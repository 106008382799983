import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      height: '6vh',
      minHeight: '60px',
      width: '60%',
      zIndex: 999,
      [theme.breakpoints.down('sm')]: {
        height: '4.2vh',
        minHeight: '45px',
      },
    },
    buttonText: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
      },
    },
    modalActions: {
      alignSelf: 'center',
      alignItems: 'center',
      backgroundColor: colors.black(0.8),
      flexDirection: 'column',
      width: '100%',
    },
    modalContent: {
      alignItems: 'center',
      border: `2px solid ${colors.lqOrange}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
);
