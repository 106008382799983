import { Grid, Input, MenuItem, Radio, Select, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { useStyles } from './styles';

const StatsModalContent = ({ game, stats }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.paper}>
      <Typography className={classes.number} noWrap>
        {`Partido ${game.game}`}
      </Typography>
      <Typography className={classes.phase} noWrap>
        {game.phase.name}
      </Typography>
      <Typography className={classes.datePlace} noWrap>
        {moment(game.datetime).format('ddd DD MMM YYYY - HH:mm')} {'- '}
        {game.stadium.city}
      </Typography>
      <div className={classes.details}>
        <div className={classes.teamLeft}>
          <div className={classes.team}>
            <img src={game.team1.flag ? game.team1.flag.url : null} className={classes.flag} />
            <Typography className={classes.teamName} noWrap>
              {game.team1.name}
            </Typography>
          </div>
          <div className={classes.draw}>
            <Typography className={classes.teamName} noWrap>
              Empate
            </Typography>
          </div>
          <div className={classes.team}>
            <img src={game.team2.flag ? game.team2.flag.url : null} className={classes.flag} />
            <Typography className={classes.teamName} noWrap>
              {game.team2.name}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.details}>
        <div className={classes.teamLeft}>
          <div className={classes.team}>
            {stats && (stats.team1 !== 0 || stats.team2 !== 0 || stats.draw !== 0) ? (
              <Typography className={classes.teamName} noWrap>
                {`${(stats.team1 * 100).toFixed(1)}%`}
              </Typography>
            ) : null}
          </div>
          <div className={classes.team}>
            <Typography className={classes.teamName} noWrap>
              {stats && (stats.team1 !== 0 || stats.team2 !== 0 || stats.draw !== 0)
                ? `${(stats.draw * 100).toFixed(1)}%`
                : 'No stats available'}
            </Typography>
          </div>
          <div className={classes.team}>
            {stats && (stats.team1 !== 0 || stats.team2 !== 0 || stats.draw !== 0) ? (
              <Typography className={classes.teamName} noWrap>
                {`${(stats.team2 * 100).toFixed(1)}%`}
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
    </Grid>
  );
};

export { StatsModalContent };
