import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import ProfilePicture from 'components/ProfilePicture';
import { ctx } from 'store';

import { useStyles } from './styles';

const StepThree = props => {
  const classes = useStyles();
  const actions = useContext(ctx.actions);

  const onChangeProfilePicture = async picture => {
    await actions.misc.setLoading(true);
    const response = await actions.groups.updateGroupPicture(picture);
    if (response.misc.notification.type === 'success') {
      props.nextStep();
    }
  };

  const skip = () => {
    props.nextStep();
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.paper}>
        <Typography className={classes.title}>Coloquemos una imagen al grupo</Typography>
        <Typography className={classes.subtitle}>
          Usa la imagen que desees para que reconozcan a tu grupo en las búsquedas!
        </Typography>
        <div className={classes.form}>
          <ProfilePicture onUpload={onChangeProfilePicture} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={skip}
          >
            Saltar
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export { StepThree };
