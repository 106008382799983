import { Grid } from '@material-ui/core';
import React from 'react';
import StepWizard from 'react-step-wizard';

import Header from 'components/Header';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

import { useStyles } from './styles';

const GroupCreation = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <Header hideTitle={false} />
      <StepWizard>
        <StepOne />
        <StepTwo />
        <StepThree />
        <StepFour />
      </StepWizard>
    </Grid>
  );
};

export { GroupCreation };
