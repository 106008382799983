import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Logo from 'assets/images/wired/lqDark.png';
import { ctx } from 'store';
import { validEmailRegex } from 'utils/regex';

import { useStyles } from './styles';

const Reset = () => {
  const history = useHistory();
  const location = useLocation();
  const { errors, getValues, handleSubmit, register, reset } = useForm();
  const classes = useStyles();
  const actions = useContext(ctx.actions);

  const onSubmit = async credentials => {
    if (credentials.password === credentials.passwordConfirmation) {
      await actions.misc.setLoading(true);
      const data = {
        ...credentials,
        ...queryString.parse(location.search),
      };
      const { misc } = await actions.auth.reset(data);
      if (misc.notification.type === 'success') {
        history.push('/login');
      }
    } else {
      actions.misc.setNotification({
        status: true,
        message: 'Passwords don not match, please verify it',
        type: 'error',
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Restablece tu contraseña de
        </Typography>
        <img alt="La Quiniela" width="240" src={Logo} style={{ marginTop: 16 }} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                {...register('password', { required: true })}
                variant="outlined"
                placeholder="Contraseña"
                name="password"
                type="password"
                // error={(errors).password}
                className={classes.inputs}
              />
              <TextField
                {...register('passwordConfirmation', { required: true })}
                variant="outlined"
                placeholder="Confirma Contraseña"
                name="passwordConfirmation"
                type="password"
                // error={(errors).password}
                className={classes.inputs}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Restablecer
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Volver al inicio de sesión
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export { Reset };
