import api, { request } from 'api';
import queryString from 'query-string';

import { save } from 'utils/storage';

export const groupsActions = {
  getGroups: state => async (queryData, groups) => {
    let jsonQuery = {
      ...queryData,
      country: ['ALL'],
    };

    const profiles = state.auth.user.profiles;
    jsonQuery =
      profiles.length && profiles[0].country && queryData.featured
        ? { ...jsonQuery, country: [...jsonQuery.country, profiles[0].country] }
        : jsonQuery;

    const query = queryString.stringify(jsonQuery);
    const response = await request.get(api.groups(query));

    if (response.error) {
      return {
        misc: {
          ...state.misc,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    if (queryData.slug && response.length) {
      return {
        groups: {
          ...state.groups,
          activeGroup: response[0],
        },
        misc: {
          ...state.misc,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }

    let featured = groups ? groups.featured : state.groups.featured;
    let all = groups ? groups.all : state.groups.all;
    const userGroups = groups ? groups.userGroups : state.groups.userGroups;

    if (queryData.featured) {
      featured = response;
    } else {
      all = response;
    }

    return {
      groups: {
        ...state.groups,
        featured,
        all,
        userGroups,
      },
      misc: {
        ...state.misc,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  getUserGroups: state => async groups => {
    const response = await request.get(api.userGroups);

    if (response.error) {
      return {
        misc: {
          ...state.misc,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    let permanentGroups = groups ? groups : state.groups;
    const memberships = state.auth.user.memberships;

    if (memberships && memberships.length) {
      for (let i = 0; i < memberships.length; i++) {
        const exist = response.find(
          group => group.id === memberships[i].group && !memberships[i].accepted
        );
        if (exist) {
          memberships[i].accepted = true;
        }
      }
    }

    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        memberships,
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };
    save('auth', auth);

    return {
      auth,
      groups: {
        ...permanentGroups,
        userGroups: response,
      },
      misc: {
        ...state.misc,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  setActiveGroup: state => async activeGroup => {
    return {
      groups: {
        ...state.groups,
        activeGroup,
      },
    };
  },
  updateGroupPicture: state => async profilePicture => {
    const { activeGroup } = state.groups;
    let formData = new FormData();

    if (profilePicture) {
      formData.append(`files.avatar`, profilePicture, profilePicture.name);
      formData.append('data', JSON.stringify({}));
    } else {
      formData.append('data', JSON.stringify({ avatar: null }));
    }

    const response = await request.put(api.group(activeGroup.id), formData, true, true);

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const isInFeaturedGroups = state.groups.featured.find(group => group.id === response.id);

    let featured = state.groups.featured;
    if (isInFeaturedGroups) {
      featured = [...state.groups.featured.filter(group => group.id !== response.id), response];
    }

    const groups = {
      ...state.groups,
      featured,
      userGroups: [...state.groups.userGroups.filter(group => group.id !== response.id), response],
      activeGroup: response,
    };

    return {
      groups,
      misc: {
        loading: false,
        notification: {
          status: true,
          message: 'Grupo actualizado correctamente!',
          type: 'success',
        },
      },
    };
  },
  createGroup: state => async data => {
    const response = await request.post(api.groups(), data, true);
    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const groups = {
      ...state.groups,
      userGroups: [...state.groups.userGroups, response.group],
      activeGroup: response.group,
    };

    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        memberships: [...state.auth.user.memberships, response.membership],
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };
    save('auth', auth);

    return {
      auth,
      groups,
      misc: {
        loading: false,
        notification: {
          status: true,
          message: 'Grupo creado!',
          type: 'success',
        },
      },
    };
  },
  updateGroup: state => async data => {
    const { activeGroup } = state.groups;

    const response = await request.put(api.group(activeGroup.id), data, true);
    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const isInFeaturedGroups = state.groups.featured.find(group => group.id === response.id);

    let featured = state.groups.featured;
    if (isInFeaturedGroups) {
      featured = [...state.groups.featured.filter(group => group.id !== response.id), response];
    }

    const groups = {
      ...state.groups,
      userGroups: [...state.groups.userGroups.filter(group => group.id !== response.id), response],
      featured,
      activeGroup: response,
    };

    return {
      groups,
      misc: {
        loading: false,
        notification: {
          status: true,
          message: 'Grupo actualizado correctamente!',
          type: 'success',
        },
      },
    };
  },
  searchGroups: state => async groupName => {
    const query = queryString.stringify({
      groupName,
      country: state.auth.user.profiles[0].country,
    });

    const response = await request.get(api.groupSearch(query));

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    let allGroups = state.groups.all;
    if (response.length) {
      for (const group of response) {
        const exist = allGroups.find(g => g.id === group.id);
        if (!exist) {
          allGroups.push(group);
        }
      }
    }

    return {
      groups: {
        ...state.groups,
        all: allGroups,
        search: response,
        searchTerm: groupName,
      },
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
};
