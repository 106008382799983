import React, { useContext } from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

import { ctx } from 'store';

import { useStyles } from './styles';

const Notification = () => {
  const classes = useStyles();

  const {
    misc: { notification },
  } = useContext(ctx.store);

  const {
    misc: { setNotification },
  } = useContext(ctx.actions);

  const handleClose = () => {
    setNotification({ status: false, message: '', type: 'success' });
  };

  return (
    <div className={classes.notificationView}>
      <Snackbar
        open={notification.status}
        autoHideDuration={5000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          severity={notification.type}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { Notification };
