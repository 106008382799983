import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    datePlace: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.8rem',
      fontWeight: 400,
      margin: theme.spacing(0, 0, 1),
      textAlign: 'left',
      textTransform: 'capitalize',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
    },
    edit: {
      width: '20%',
      [theme.breakpoints.down('md')]: {
        width: '15%',
      },
    },
    flag: {
      width: '90%',
      [theme.breakpoints.down('md')]: {
        width: '90%',
        maxWidth: 260,
      },
    },
    grid: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    infoColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    number: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      margin: theme.spacing(0),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    paper: {
      backgroundColor: colors.white(0.2),
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
    },
    penaltyIcon: {
      color: colors.lqOrange,
      fontSize: 20,
    },
    phase: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '1rem',
      fontWeight: 400,
      margin: theme.spacing(0),
      textAlign: 'left',
    },
    points: {
      alignSelf: 'center',
      color: colors.lqOrange,
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 400,
      margin: theme.spacing(-1, 0, 0),
      textAlign: 'left',
    },
    predictions: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(0.5, 0),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1.5, 0),
      },
    },
    predictionsFull: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: theme.spacing(1.5, 0),
      width: '100%',
    },
    predictionCell: {
      alignItems: 'center',
      display: 'flex',
    },
    predictionText: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2.8rem',
      lineHeight: '2.3rem',
      margin: theme.spacing(1, 1, 0),
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.8rem',
      },
    },
    results: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    resultText: {
      color: colors.white(1),
      fontFamily: 'Teko',
      fontSize: '2.8rem',
      lineHeight: '2.3rem',
      margin: theme.spacing(1, 1, 0),
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.8rem',
      },
    },
    statsButton: {
      backgroundColor: colors.lqBlue,
      display: 'flex',
      height: 30,
      width: 30,
    },
    statsIcon: {
      height: 16,
      width: 16,
    },
    team: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    teamLeft: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
    teamName: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.9rem',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '0.8rem',
      },
    },
    teamRight: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
    vsText: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.6rem',
      justifyContent: 'center',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.6rem',
        margin: theme.spacing(0),
      },
    },
  })
);
