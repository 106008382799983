import { Button, CssBaseline, Grid, Select, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import countryList from 'react-select-country-list';

import Header from 'components/Header';
import ProfilePicture from 'components/ProfilePicture';
import { ctx } from 'store';
// import { validEmailRegex } from 'utils/regex';

import { useStyles } from './styles';

const Profile = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = useState(null);
  const options = useMemo(() => countryList().getData(), []);
  const actions = useContext(ctx.actions);
  const {
    auth: { user },
  } = useContext(ctx.store);

  useEffect(() => {
    setSelected(user.profiles[0].country);
  }, []);

  const goToHome = () => {
    history.push('/home');
  };

  const onChangeProfilePicture = async picture => {
    await actions.misc.setLoading(true);
    await actions.auth.updateProfilePicture(picture);
  };

  const handleChange = event => {
    setSelected(event.target.value);
  };

  const submitChange = async () => {
    if (user.profiles[0].country !== selected) {
      await actions.misc.setLoading(true);
      await actions.auth.updateProfile({ country: selected });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Header />
      <CssBaseline />
      <div className={classes.paper}>
        <Grid className={classes.socialGrid}>
          <ProfilePicture
            onUpload={onChangeProfilePicture}
            profilePicture={user.profiles[0].avatar ? user.profiles[0].avatar.url : null}
          />
        </Grid>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            placeholder="Username"
            name="username"
            className={classes.inputs}
            InputProps={{
              classes: {
                root: classes.inputProps,
                disabled: classes.inputPropsDisabled,
              },
            }}
            disabled
            value={user.username}
          />
          <TextField
            variant="outlined"
            placeholder="Username"
            name="username"
            className={classes.inputs}
            InputProps={{
              classes: {
                root: classes.inputProps,
                disabled: classes.inputPropsDisabled,
              },
            }}
            disabled
            value={`${user.firstName} ${user.lastName}`}
          />
          <Select
            variant="outlined"
            native
            value={selected}
            onChange={handleChange}
            className={classes.inputSelect}
            placeholder="Seleciona tú país"
            InputProps={{
              classes: {
                root: classes.inputProps,
                disabled: classes.inputPropsDisabled,
              },
            }}
          >
            {[{ label: 'Selecciona un país', value: null }, ...options].map(
              (countryOption, index) => (
                <option key={index} value={countryOption.value}>
                  {countryOption.label}
                </option>
              )
            )}
          </Select>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={submitChange}
            disabled={user.profiles[0].country === selected}
          >
            Guardar
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={goToHome}
          >
            Volver al Home
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export { Profile };
