import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    activeTournamentPaper: {
      alignItems: 'center',
      backgroundColor: colors.white(0.2),
      backgroundPosition: 'center',
      backgroundSize: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: 260,
      justifyContent: 'center',
      margin: theme.spacing(0, 2),
      minWidth: 450,
      [theme.breakpoints.down('sm')]: {
        height: 160,
        margin: theme.spacing(0, 1),
        minWidth: 260,
      },
    },
    cup: {
      filter:
        'invert(99%) sepia(2%) saturate(1169%) hue-rotate(256deg) brightness(122%) contrast(100%)',
      width: 120,
      [theme.breakpoints.down('sm')]: {
        width: 80,
      },
    },
    cupInfo: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
    cupLink: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      textDecoration: 'none',
      width: '100%',
    },    
    link: {
      color: 'white',
      fontFamily: 'Poppins',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
    },
    pastCup: {
      filter:
        'invert(99%) sepia(2%) saturate(1169%) hue-rotate(256deg) brightness(122%) contrast(100%)',
      width: 90,
      [theme.breakpoints.down('sm')]: {
        width: 60,
      },
    },
    pastTournamentPaper: {
      alignItems: 'center',
      backgroundColor: colors.white(0.2),
      backgroundPosition: 'center',
      backgroundSize: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: 200,
      justifyContent: 'center',
      margin: theme.spacing(0, 2),
      minWidth: 346,
      [theme.breakpoints.down('sm')]: {
        height: 150,
        margin: theme.spacing(0, 1),
        minWidth: 240,
      },
    },
  })
);
