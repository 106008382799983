import { createStyles, makeStyles } from '@material-ui/core/styles';

import background from 'assets/images/background.png';
import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    actionButton: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      margin: '2vh 0',
      height: 50,
      width: '40%',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
        height: 50,
        width: 'auto',
      },
    },
    actionsGrid: {
      alignItems: 'center',
      backgroundColor: colors.white(0.2),
      display: 'flex',
      flexDirection: 'row',
      height: 100,
      justifyContent: 'space-between',
      margin: theme.spacing(0, 0, 3),
      padding: theme.spacing(0, 2),
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: 'auto',
        padding: theme.spacing(0),
      },
    },
    actionLinkButton: {
      alignContent: 'center',
      alignItems: 'center',
      color: colors.black(1),
      display: 'flex',
      justifyContent: 'center',
      textDecoration: 'none',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
      },
    },
    actionSearchGrid: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '0 5%',
      width: '40%',
      [theme.breakpoints.down('md')]: {
        margin: '0 2.5%',
        width: '95%',
      },
    },
    content: {
      height: 'calc(100% - 100px)',
      marginTop: 100,
      overflowY: 'scroll',
      padding: theme.spacing(1, 1),
      width: '100%',
    },
    featuredList: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      maxHeight: 260,
      overflowX: 'scroll',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        maxHeight: 200,
      },
    },
    groupsList: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxHeight: 'none',
      },
    },
    inputProps: {
      backgroundColor: colors.black(0.3),
      color: colors.white(1),
      maxHeight: 50,
    },
    inputs: {
      border: '0px solid transparent',
      display: 'flex',
      color: colors.white(1),
      margin: theme.spacing(0),
      maxHeight: 50,
      width: '70%', // Fix IE 11 issue.
    },
    or: {
      fontSize: '1.2rem',
      color: colors.white(1),
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    root: {
      background: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flex: 1,
      height: '100vh',
      position: 'fixed',
      width: '100%',
    },
  })
);
