import { createTheme } from '@material-ui/core/styles';

export const colors = {
  lqOrange: '#F49F11',
  lqBlue: '#12203C',
  lqGray: '#DEDEDE',
  lqBlack: '#010101',
  lqWhite: '#F7F7F7',
  black: (opacity) =>  `rgba(0,0,0, ${opacity})`,
  white: (opacity) =>  `rgba(255, 255, 255, ${opacity})`,
  greenCruise: '#c2efd8',
  blueGrey: '#8f8e94',
  salmon: '#f3887b',
  carolinaBlue: '#7eadff',
  yellowCream: '#ffe8a8',
  coral: '#f55b4d',
  veryLightBlue: '#eaedf2',
  tangerine: '#f3a176',
  battleshipGrey: 'rgba(109, 114, 120, 0.27)',
  mutedBlue: 'rgba(62, 114, 154, 0.2)',
  darkGreen: '#227557',
  line: 'rgba(0, 0, 0, 0.08)',
  disabledTextGrey: '#464545',
  darkGreyBlue: '#2c3e50',
  wildSand: '#f4f4f4',
  darkMintGreen: '#21bf73',
  lightishGreen: '#7ad079',
  errorRed: 'rgb(158, 17, 6)',
  dusk: '#414970',
  danger: '#ae2525',
  dangerHover: '#ca1d1d',
  lightOrange: '#f5a54d',
  darkPlaceholderText: '#5a4545',
  successGreen: '#0b860b',
  charcoalGrey: '#4a4b50',
  paleGrey: '#cabbbb21',
  greyBg: '#e1ebea',
  orangeBrand: '#ffc23b',
  blueBrand: '#4aa8dc',
  greyBrand: '#373d41',
  greenBrand: '#3cb945',
  redBrand: '#e34a4c',
  textGrey3e: '#3e3e3e',
  textGrey5e: '#5e5e5e',
  blueBrandCompare: 'rgba(74,168,220,0.6)',
  greenBrandCompare: 'rgba(60,185,69,0.6)',
  redBrandCompare: 'rgba(227,74,76,0.6)',
};

const theme = createTheme({
  palette: {
    action: {
      disabled: '#ffffff',
      disabledBackground: '#dddddd',
    },
    error: { main: colors.errorRed },
    primary: {
      main: colors.lqBlue,
    },
    secondary: {
      main: colors.lqOrange,
    },
    customBackground: {
      main: colors.lqGray,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: 16,
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: colors.lqOrange,
        fontSize: '1.8rem',
        fontWeight: 'bold',
      },
      textSecondary: {
        color: colors.lqBlue,
        fontSize: '1.8rem',
        fontWeight: 'bold',
      },
      root: {
        boxShadow: `1px 1px 8px 0 ${colors.mutedBlue}`,
        '&:disabled': {
          backgroundColor: colors.lqGray,
          color: 'black',
          boxShadow: 'none',
        },
      },
    },
    MuiTypography: {
      root: {
        color: colors.darkGreyBlue,
      },
    },
    MuiInputBase: {
      root: {
        lineHeight: 'inherit',
      },
      input: {
        padding: '6px 0 6px',
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.errorRed,
      },
    },
  },
});

export default theme;
