import { createStyles, makeStyles } from '@material-ui/core/styles';

import background from 'assets/images/background.png';
import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    button: {
      fontWeight: 'bold',
      margin: theme.spacing(3, 0, 3),
      minHeight: 50,
      textTransform: 'none',
    },
    form: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      width: '100%', // Fix IE 11 issue.
    },
    image: {
      background: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    inputs: {
      fontFamily: 'Poppins',
      margin: theme.spacing(1, 0),
      width: '100%', // Fix IE 11 issue.
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      margin: theme.spacing(0, 6),
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        margin: 0,
        padding: theme.spacing(5, 3),
      },
    },
    root: {
      height: '100vh',
    },
    socialGrid: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: theme.spacing(3, 0),
      width: '100%',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: theme.spacing(0, 0, 4),
      },
    },
    text: {
      color: colors.lqBlue,
      fontSize: '1rem',
      width: '100%',
    },
    title: {
      color: colors.lqBlue,
      fontFamily: 'Teko',
      fontSize: '4rem',
      fontWeight: '500',
      [theme.breakpoints.down('md')]: {
        fontSize: '3rem',

      },      
    },
  })
);
