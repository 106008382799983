import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from '../../styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    inputs: {
      fontFamily: 'Poppins',
      margin: theme.spacing(1, 0),
      width: '100%', // Fix IE 11 issue.
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);
