import { Button, CssBaseline, Grid, Select, TextField, Typography } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import countryList from 'react-select-country-list';

// import Google from 'assets/images/google.svg';
// import Microsoft from 'assets/images/microsoft.svg';
import Logo from 'assets/images/wired/lqDark.png';
import { ctx } from 'store';
import { validEmailRegex } from 'utils/regex';

import { useStyles } from './styles';

const Register = () => {
  const classes = useStyles();
  const history = useHistory();
  const options = useMemo(() => countryList().getData(), []);
  const { errors, getValues, handleSubmit, register, reset } = useForm();
  const actions = useContext(ctx.actions);

  const onSubmit = async credentials => {
    await actions.misc.setLoading(true);
    const response = await actions.auth.register(credentials);
    if (response.misc.notification.type === 'success') {
      history.push('/home');
    } else {
      reset({ ...getValues(), password: '' });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography className={classes.title}>Regístrate en</Typography>
        <img alt="La Quiniela" width="240" src={Logo} style={{ marginBottom: 16 }} />
        {/* <Grid className={classes.socialGrid}>
          <a
            className={classes.slackButton}
            href={
              `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?`
              // client_id=${MS_CLIENT_ID}
              // &response_type=code
              // &redirect_uri=${MS_LOGIN_REDIRECT_URI}
              // &response_mode=query
              // &scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read%20
              // https%3A%2F%2Fgraph.microsoft.com%2Fprofile%20`
            }
          >
            <img alt="Sign in with Microsoft" width="240" src={Google} />
          </a>
          <a
            className={classes.slackButton}
            href={
              `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?`
              // client_id=${MS_CLIENT_ID}
              // &response_type=code
              // &redirect_uri=${MS_LOGIN_REDIRECT_URI}
              // &response_mode=query
              // &scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read%20
              // https%3A%2F%2Fgraph.microsoft.com%2Fprofile%20`
            }
          >
            <img alt="Sign in with Microsoft" width="240" src={Microsoft} />
          </a>
        </Grid> */}
        {/* <Typography className={classes.text}>o regístrate con tu correo electrónico</Typography> */}
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            {...register('email', {
              required: true,
              pattern: {
                value: validEmailRegex,
                message: 'Correo electrónico inválido',
              },
            })}
            placeholder="Correo electrónico"
            name="email"
            autoFocus
            className={classes.inputs}
          />
          <TextField
            variant="outlined"
            {...register('username', {
              required: true,
            })}
            placeholder="Username"
            name="username"
            className={classes.inputs}
          />
          <TextField
            variant="outlined"
            {...register('firstName', {
              required: true,
            })}
            placeholder="Nombre"
            name="firstName"
            className={classes.inputs}
          />
          <TextField
            variant="outlined"
            {...register('lastName', {
              required: true,
            })}
            placeholder="Apellido"
            name="lastName"
            className={classes.inputs}
          />
          <TextField
            {...register('password', { required: true })}
            variant="outlined"
            placeholder="Contraseña"
            name="password"
            type="password"
            // error={(errors as any).password}
            className={classes.inputs}
          />
          <Select
            {...register('country', { required: true })}
            variant="outlined"
            native
            name="country"
            // onChange={handleChange}
            className={classes.inputs}
            placeholder="Seleciona tú país"
          >
            {[{ label: 'Selecciona un país', value: null }, ...options].map((countryOption, index) => (
              <option key={index} value={countryOption.value}>
                {countryOption.label}
              </option>
            ))}
          </Select>          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Regístrate
          </Button>
          <Grid item>
            <Link to="/login" variant="body2">
              ¿Ya tienes cuenta? Ingresa aquí
            </Link>
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export { Register };
