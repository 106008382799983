import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import Header from 'components/Header';
import FeaturedGroup from 'components/FeaturedGroup';
import GroupList from 'components/GroupList';

import { ctx } from 'store';
import { colors } from 'styles';

import { useStyles } from './styles';

const Groups = () => {
  const classes = useStyles();
  const actions = useContext(ctx.actions);
  const history = useHistory();
  const [fetched, setFetched] = useState(false);
  const [fetchedMe, setFetchedMe] = useState(false);

  const { handleSubmit, register, reset, getValues } = useForm();

  const {
    groups: { all, featured, userGroups },
    auth: { user },
  } = useContext(ctx.store);

  useEffect(() => {
    const fetch = async () => {
      let response = undefined;
      if (featured && !featured.length) {
        await actions.misc.setLoading(true);
        response = await actions.groups.getGroups({ featured: true });
      }

      if (userGroups && !userGroups.length) {
        await actions.misc.setLoading(true);
        response = await actions.groups.getUserGroups(response ? response.groups : undefined);
      }

      if (all && !all.length) {
        await actions.misc.setLoading(true);

        response = await actions.groups.getGroups(
          { featured: false },
          response ? response.groups : undefined
        );
      }

      await actions.misc.setLoading(false);
      setFetched(true);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!fetchedMe) {
      actions.auth.me();
      setFetchedMe(true);
    }
  }, [fetched]);

  const handleSearch = async data => {
    if (data.search && data.search.length) {
      await actions.misc.setLoading(true);
      const response = await actions.groups.searchGroups(data.search);
      if (response.groups.search.length) {
        reset({ search: '' });
        history.push(`/search`);
      }
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Header hideTitle={false} />
      <Grid direction="column" className={classes.content}>
        {!!featured.length && <FeaturedGroup groups={featured} user={user} />}
        <Grid xs={12} className={classes.actionsGrid}>
          <div className={classes.actionSearchGrid}>
            <Button
              variant="contained"
              color="primary"
              aria-label="know-more"
              className={classes.actionButton}
              type="button"
            >
              <Link
                className={classes.actionLinkButton}
                style={{ color: colors.lqOrange }}
                to={{ pathname: `/create` }}
              >
                Crea un Grupo
              </Link>
            </Button>
          </div>
          <Typography className={classes.or}>ó</Typography>
          <form className={classes.actionSearchGrid} onSubmit={handleSubmit(handleSearch)}>
            <TextField
              {...register('search', {
                required: true,
              })}
              label="Busca un Grupo"
              InputLabelProps={{
                style: {
                  color: colors.lqOrange,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.inputProps,
                },
              }}
              variant="outlined"
              color="secondary"
              className={classes.inputs}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.actionButton}
              type="submit"
            >
              Buscar
            </Button>
          </form>
        </Grid>
        {!!userGroups.length && (
          <GroupList groups={userGroups} title="Mis grupos" showLength={true} />
        )}
        {!!all.length && <GroupList groups={all} title="Otros Grupos" />}
      </Grid>
    </Grid>
  );
};

export { Groups };
