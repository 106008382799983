import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router-dom';
import Flag from 'react-world-flags';

import { ctx } from 'store';
import { colors } from 'styles';

import { useStyles } from './styles';

const Ranking = () => {
  const { tournamentId, groupId } = useParams();

  const {
    auth: { user },
    groups: { activeGroup },
    misc: { fetching },
    rankings,
    tournaments,
  } = useContext(ctx.store);
  const actions = useContext(ctx.actions);

  const classes = useStyles();

  useEffect(() => {
    const fetchTournaments = async () => {
      let tournamentsResponse = tournaments;
      if (
        tournamentId &&
        (tournaments.currentTournaments.length || tournaments.pastTournaments.length)
      ) {
        let tournament = tournaments.currentTournaments.find(
          tournament => tournament.code === tournamentId
        );
        if (!tournament) {
          tournament = tournaments.pastTournaments.find(
            tournament => tournament.code === tournamentId
          );
        }
        if (tournament.isFinished && (!tournament.games || !tournament.games.length)) {
          tournamentsResponse = await actions.tournaments.getGames(tournament.code);
        }
        let finalTournament = tournaments.currentTournaments.find(
          tournament => tournament.code === tournamentId
        );
        if (!finalTournament) {
          finalTournament = tournaments.pastTournaments.find(
            tournament => tournament.code === tournamentId
          );
        }
        await actions.tournaments.setActiveTournament(
          finalTournament,
          tournamentsResponse.tournaments
        );
      }
    };

    if (tournamentId) {
      fetchTournaments();
    } else {
      history.push('/home');
    }
  }, [activeGroup]);

  const fetchRankingPage = async () => {
    await actions.misc.setFetching(true);

    await actions.rankings.getRanking({
      tournament: tournamentId,
      groupId: activeGroup.name,
      page:
        rankings[tournamentId] &&
        rankings[tournamentId][!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name] &&
        rankings[tournamentId][!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name].nextPage
          ? rankings[tournamentId][!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name]
              .nextPage
          : 1,
    });
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: fetching,
    hasNextPage:
      !rankings[tournamentId] ||
      !rankings[tournamentId][!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name] ||
      rankings[tournamentId][!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name].nextPage
        ? true
        : false,
    onLoadMore: fetchRankingPage,
    disabled: activeGroup ? false : true,
    rootMargin: '0px 0px 10px 0px',
    delayInMs: 500,
  });

  return (
    <Grid item xs={12}>
      <Typography className={classes.rankingTitle}>
        Ranking {activeGroup && activeGroup.name ? activeGroup.name : 'LQ'}
      </Typography>
      <List className={classes.list}>
        <ListSubheader className={classes.listHeader}>
          <ListItemText className={classes.columnPosition}>
            <Typography className={classes.headerText}>Posición</Typography>
          </ListItemText>
          <ListItemText className={classes.columnUser} style={{ justifyContent: 'center' }}>
            <Typography className={classes.headerText}>Usuario</Typography>
          </ListItemText>
          <ListItemText className={classes.columnPoints}>
            <Typography className={classes.headerText}>Puntos</Typography>
          </ListItemText>
        </ListSubheader>
        {(rankings[tournamentId] &&
        rankings[tournamentId][!activeGroup.name ? 'LQ' : activeGroup.name] &&
        rankings[tournamentId][!activeGroup.name ? 'LQ' : activeGroup.name].ranking.length
          ? rankings[tournamentId][!activeGroup.name ? 'LQ' : activeGroup.name].ranking
          : []
        ).map((userItem, index) => (
          <ListItem
            key={index}
            className={classes.listItem}
            style={
              index < 3 || user.username === userItem.username
                ? { border: `3px solid ${colors.lqOrange}` }
                : null
            }
          >
            <ListItemText className={classes.columnPosition}>
              <Typography className={classes.itemTextOrange}>{userItem.position}</Typography>
            </ListItemText>
            <div className={classes.columnUser}>
              <Avatar
                src={
                  user.username === userItem.username
                    ? user.profiles[0].avatar.url
                    : userItem.avatar
                }
                className={classes.avatar}
              />
              <Typography className={classes.itemText} noWrap>
                {userItem.username}
              </Typography>
              <Flag
                code={
                  user.username === userItem.username ? user.profiles[0].country : userItem.country
                }
                className={classes.flag}
              />
            </div>
            <ListItemText className={classes.columnPoints}>
              <Typography className={classes.itemTextOrange}>{userItem.points}</Typography>
            </ListItemText>
          </ListItem>
        ))}
        <div ref={infiniteRef} />
        {fetching && <ListItem className={classes.loadingGrid}>Loading</ListItem>}
      </List>
    </Grid>
  );
};

export { Ranking };
