/* eslint-disable sonarjs/no-duplicate-string */
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export default makeStyles(theme =>
  createStyles({
    carousel: {
      flex: 1,
      padding: '0 1vw',
      position: 'relative',
      zIndex: 10,
    },
    detailsTextOneFullWidth: {
      alignItems: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.9rem',
      margin: '1vh 0',
      textAlign: 'left',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        fontSize: '0.8rem',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    knowMoreButton: {
      fontFamily: 'Poppins',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      margin: '2vh 0',
      width: '40%',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
    linkButton: {
      width: '100%',
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      color: colors.black(1),
      textDecoration: 'none',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.7rem',
      },
    },
    oneFullWidth: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      padding: '0 4vw',
      width: '100%',
      zIndex: 999,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: 'auto',
        margin: '2vh 0',
        minHeight: 260,
        padding: 0,
      },
    },
    oneFullWidthImage: {
      alignSelf: 'center',
      backgroundPosition: 'center 30%',
      backgroundSize: 'cover',
      borderRadius: 20,
      color: colors.white(1),
      display: 'flex',
      height: 300,
      textAlign: 'center',
      width: 300,
      zIndex: 0,
      [theme.breakpoints.down('md')]: {
        height: '50vw',
        maxHeight: 300,
        width: '50vw',
        maxWidth: 300,
      },
    },
    oneFullWidthLeft: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '60%',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-start',
        height: 'auto',
        width: '100%',
      },
    },
    oneFullWidthRight: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: 320,
      width: '40%',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        height: 'auto',
        justifyContent: 'center',
        padding: '2vh 0 2vh',
        width: '100%',
      },
    },
    root: {
      alignItems: 'center',
      backgroundColor: colors.white(0),
      display: 'flex',
      flexDirection: 'row',
      height: '24vh',
      minHeight: '420px',
      padding: '0 4vw',
      width: '100%',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        padding: '0 0.2vw',
      },
    },
    titleOneFullWidth: {
      color: colors.white(1),
      fontSize: '4rem',
      fontFamily: 'Teko',
      fontWeight: 'bold',
      margin: theme.spacing(0, 0, 1),
      textAlign: 'left',
      width: '80%',
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
        lineHeight: 1,
        textAlign: 'center',
        width: '100%',
      },
    },
    titleTextOneFullWidth: {
      color: colors.white(1),
      fontSize: '1rem',
      margin: theme.spacing(1, 0, 2),
      textAlign: 'left',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        margin: '1vh 0',
        textAlign: 'center',
        width: '80%',
      },
    },
  })
);
