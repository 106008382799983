import React from 'react';
import Lottie from 'react-lottie';
import { Box, Typography } from '@material-ui/core';

import animation from './animation.json';
import { useStyles } from './styles';

const Loading = ({ containerHeight = '50vh', animationHeight = 300, animationWidth = 300 }) => {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };
  return (
    <Box
      display="flex"
      height={containerHeight}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Lottie options={defaultOptions} height={animationHeight} width={animationWidth} />
      {/* <div className={classes.textWrapper}>
        <Typography className={classes.text}>LOADING</Typography>
      </div> */}
    </Box>
  );
};

export { Loading };
