import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TheatersOutlined } from '@material-ui/icons';
import { colors } from 'styles';

export default makeStyles(theme =>
  createStyles({
    avatar: {
      height: 150,
      width: 150,
    },
    avatarContainer: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'nowrap',
        flexDirection: 'column',
      },
    },
    avatarImageContainer: {
      padding: 0,
      height: 160,
      width: 160,
      flexBasis: '20%',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(3),
      },
      borderRadius: '50%',
      position: 'relative',
      '&:hover': {
        '& .remove-hover': {
          display: 'flex',
        },
      },
    },
    bottomMessage: {
      padding: '8px 0px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    chooseFileButton: {
      zIndex: 2,
      position: 'absolute',
      bottom: 0,
      left: 100,
      backgroundColor: `${colors.white} !important`,
      '& input[type="file"]': {
        position: 'absolute',
        opacity: 0,
        fontSize: 0,
        width: '100%',
        cursor: 'pointer',
        height: '100%',
      },
    },
    editButton: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.lqOrange,
      borderRadius: '50%',
      color: colors.lqBlue,
      display: 'flex',
      padding: theme.spacing(2),
      width: 40,
      height: 40,
    },
    removePhoto: {
      fontSize: '0.9rem',
      textTransform: 'capitalize',
      width: 80,
    },
    uploadAvatar: {
      width: '100%',
      margin: 0,
      padding: 0,
      paddingLeft: theme.spacing(2),
    },
  })
);
