import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    datePlace: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.8rem',
      fontWeight: 400,
      margin: theme.spacing(0, 0, 1),
      textAlign: 'left',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
    },
    flag: {
      maxHeight: 80,
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '75%',
      },
    },
    grid: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },
    noTournamentsGrid: {
      alignItems: 'center',
      display: 'flex',
      height: 200,
      justifyContent: 'center',
    },
    noTournamentsText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    paper: {
      backgroundColor: colors.white(0.2),
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
    },
    phase: {
      alignSelf: 'center',
      color: colors.lqOrange,
      display: 'flex',
      fontSize: '1rem',
      fontWeight: 400,
      margin: theme.spacing(-1, 0, 0),
      textAlign: 'left',
    },
    resultText: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '3.5rem',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '3rem',
      },
    },
    root: {
      display: 'flex',
      height: '92%',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    sectionTitle: {
      color: colors.lqOrange,
      display: 'flex',
      fontFamily: 'Teko',
      fontSize: '2.4rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.4rem',
        lineHeight: '2.6rem',
        margin: theme.spacing(1, 0),
      },
    },
    scrollGrid: {
      overflowY: 'scroll',
      height: '100%',
    },
    team: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    teamLeft: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
    teamName: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.9rem',
      fontWeight: 400,
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '0.8rem',
      },
    },
    teamRight: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      marginLeft: theme.spacing(1),
    },
    vsText: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.6rem',
      fontWeight: 500,
      margin: theme.spacing(0, 4),
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '1.4rem',
      },
    },
  })
);
