import React, { useContext } from 'react';

import Loading from 'components/animations/Loading';

import { ctx } from 'store';

import { useStyles } from './styles';

const Loader = () => {
  const {
    misc: { loading },
  } = useContext(ctx.store);
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <div className={classes.loading}>
          <Loading containerHeight="100vh" />
        </div>
      ) : null}
    </>
  );
};

export { Loader };
