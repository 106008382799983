import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Done from 'components/animations/Done';
import { ctx } from 'store';
import { colors } from 'styles';

import { useStyles } from './styles';

const StepFour = () => {
  const classes = useStyles();
  const {
    groups: { activeGroup },
  } = useContext(ctx.store);

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.paper}>
        <Typography className={classes.title}>
          Grupo Creado con Éxito
        </Typography>
        <Done />
        <Typography className={classes.subtitle}>
          Cualquier dato que quieras corregir puedes entrar en el grupo y hacerlo
        </Typography>
        <div className={classes.form}>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            <Link
              className={classes.buttonLink}
              to={`/{activeGroup.slug}`}
              style={{ color: colors.black(1) }}
            >
              Ir a mi Grupo
            </Link>
          </Button> */}
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.button}
          >
            <Link className={classes.buttonLink} to={`/home`} style={{ color: colors.lqOrange }}>
              Ir al Home
            </Link>
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export { StepFour };
