import api, { request } from 'api';
import queryString from 'query-string';

import { get, save } from 'utils/storage';

export const rankingsActions = {
  getRanking: state => async queryData => {
    let ranking = get('rnk');

    if (
      ranking &&
      ranking[`${queryData.tournament}`] &&
      ranking[`${queryData.tournament}`][queryData.groupId ? queryData.groupId : 'LQ'] &&
      (!ranking[`${queryData.tournament}`][queryData.groupId ? queryData.groupId : 'LQ']
        .nextPage ||
        ranking[`${queryData.tournament}`][queryData.groupId ? queryData.groupId : 'LQ']
          .nextPage > queryData.page)
    ) {
      return {
        rankings: {
          ...state.rankings,
          [queryData.tournament]: {
            ...state.rankings[queryData.tournament],
            [queryData.groupId ? queryData.groupId : 'LQ']: {
              ranking:
                ranking[`${queryData.tournament}`][
                  queryData.groupId ? queryData.groupId : 'LQ'
                ].ranking,
              nextPage:
                ranking[`${queryData.tournament}`][
                  queryData.groupId ? queryData.groupId : 'LQ'
                ].nextPage,
            },
          },
        },
        misc: {
          ...state.misc,
          fetching: false,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }

    const query = queryString.stringify(queryData);
    const response = await request.get(api.ranking(query));
    if (response.error) {
      return {
        ...state.misc,
        misc: {
          fetching: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    let nextPage;
    if (response.length === 100) {
      nextPage = !queryData.page || queryData.page === 1 ? 2 : queryData.page + 1;
    } else {
      nextPage = false;
    }

    let responseToBeSent;
    if (state.rankings[queryData.tournament]) {
      const ranking = state.rankings[queryData.tournament][
        !queryData.groupId ? 'LQ' : queryData.groupId
      ]
        ? [
            ...state.rankings[queryData.tournament][
              !queryData.groupId ? 'LQ' : queryData.groupId
            ].ranking,
            ...response,
          ]
        : response;

      responseToBeSent = {
        ...state.rankings[queryData.tournament],
        [!queryData.groupId ? 'LQ' : queryData.groupId]: {
          ranking,
          nextPage,
        },
      };
    } else {
      responseToBeSent = {
        [!queryData.groupId ? 'LQ' : queryData.groupId]: {
          ranking: response,
          nextPage,
        },
      };
    }

    const rnk = ranking
      ? {
          ...ranking,
          [queryData.tournament]: responseToBeSent,
        }
      : { [queryData.tournament]: responseToBeSent };

    save('rnk', rnk);

    return {
      rankings: {
        ...state.rankings,
        [queryData.tournament]: responseToBeSent,
      },
      misc: {
        ...state.misc,
        fetching: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
};
