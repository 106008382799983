import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    datePlace: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.9rem',
      fontWeight: 400,
      margin: theme.spacing(0, 0, 1),
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem',
        fontWeight: 'normal',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
    },
    edit: {
      width: 14,
    },
    flag: {
      width: '80%',
      [theme.breakpoints.down('md')]: {
        width: '90%',
        // padding: theme.spacing(2),
      },
    },
    grid: {
      height: '100%',
      // padding: theme.spacing(10, 20),
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        // padding: theme.spacing(2),
      },
    },
    inputProps: {
      border: '1px solid transparent',
      color: colors.white(1),
      fontSize: '2.4rem',
      fontWeight: 'bold',
    },
    inputs: {
      border: '1px solid transparent',
      color: colors.white(1),
      fontFamily: 'Poppins',
      fontSize: '2rem',
      margin: theme.spacing(1, 0),
      textAlign: 'center',
      width: 64, // Fix IE 11 issue.
    },
    number: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      margin: theme.spacing(1, 0, 0),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    paper: {
      backgroundColor: colors.black(0.8),
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
    },
    penaltiesGrid: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    phase: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 400,
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    points: {
      alignSelf: 'center',
      color: colors.lqOrange,
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 400,
      margin: theme.spacing(-1, 0, 0),
      textAlign: 'left',
    },
    predictionText: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2.8rem',
      lineHeight: '2.3rem',
      margin: theme.spacing(1, 0, 0),
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.8rem',
      },
    },
    radioGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '60%',
    },
    results: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    resultText: {
      color: colors.white(1),
      fontFamily: 'Teko',
      fontSize: '2.8rem',
      lineHeight: '4rem',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.8rem',
      },
    },
    team: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    teamLeft: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
    teamName: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 400,
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '0.8rem',
      },
    },
    teamRight: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      marginLeft: theme.spacing(1),
    },
    underline: {
      borderBottomColor: colors.white(1),
    },
    vsText: {
      alignSelf: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.7rem',
      lineHeight: '3.5rem',
      margin: theme.spacing(0, 4),
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '0.7rem',
      },
    },
  })
);
