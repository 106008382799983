// import detectBrowserLanguage from 'detect-browser-language';
import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// import queryString from 'query-string';

import { ctx } from '../../store';

const Handler = () => {
  const history = useHistory();
  const location = useLocation();
  const actions = useContext(ctx.actions);

  useEffect(() => {
    const asyncFetch = async () => {
      await actions.misc.setLoading(true);
      // const queryParams = queryString.parse(location.search);
      let response;
      response = await actions.auth.googleLoginCallback(location.search);
      if (response.auth.isAuthenticated) {
        history.push('/home');
      } else {
        history.push('/login');
      }
    };

    asyncFetch();
  }, []);

  return <div />;
};

export { Handler };
