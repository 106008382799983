import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(() =>
  createStyles({
    text: {
      color: colors.white(1),
      fontFamily: 'Ubuntu',
      fontSize: '2.4rem',
      fontWeight: 'bold',
      lineHeight: '2.2rem',
    },
    textWrapper: {
      marginTop: -50,
      textAlign: 'center',
    },
  })
);
