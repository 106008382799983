import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import GameCard from 'components/GameCard';
import PredictionModalContent from 'components/PredictionModalContent';
import StatsModalContent from 'components/StatsModalContent';
import Modal from 'components/Modal';
import { ctx } from 'store';

import { useStyles } from './styles';

const TournamentHome = () => {
  const {
    auth: { user },
    groups: { activeGroup },
    tournaments,
    predictions,
  } = useContext(ctx.store);
  const actions = useContext(ctx.actions);
  const classes = useStyles();
  const { tournamentId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ game: {}, prediction: {} });
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const [statsModalData, setStatsModalData] = useState({ game: {}, stats: {} });
  const [scoreTeam1, setScoreTeam1] = useState(null);
  const [scoreTeam2, setScoreTeam2] = useState(null);
  const [penaltiesWinner, setPenaltiesWinner] = useState(null);

  useEffect(() => {
    const fetchTournaments = async () => {
      let tournamentsResponse = tournaments;
      if (
        tournamentId &&
        (tournaments.currentTournaments.length || tournaments.pastTournaments.length)
      ) {
        let tournament = tournaments.currentTournaments.find(
          tournament => tournament.code === tournamentId
        );
        if (!tournament) {
          tournament = tournaments.pastTournaments.find(
            tournament => tournament.code === tournamentId
          );
        }
        if (!tournament.games || tournament.games.length === 0) {
          tournamentsResponse = await actions.tournaments.getGames(tournament.code);
        }
        let finalTournament = tournaments.currentTournaments.find(
          tournament => tournament.code === tournamentId
        );
        if (!finalTournament) {
          finalTournament = tournaments.pastTournaments.find(
            tournament => tournament.code === tournamentId
          );
        }
        await actions.tournaments.setActiveTournament(
          finalTournament,
          tournamentsResponse.tournaments
        );
        await actions.predictions.getPredictions(finalTournament.code, activeGroup.id);
        if (
          !finalTournament.isFinished &&
          activeGroup &&
          !user.histories.find(
            history =>
              history.tournament === finalTournament.code && history.groupName === activeGroup.name
          )
        ) {
          await actions.histories.createHistory(finalTournament.code);
        }
      }
    };

    if (tournamentId) {
      fetchTournaments();
    } else {
      history.push('/home');
    }
  }, [activeGroup]);

  const handleEditModal = (game, prediction) => {
    if (prediction) {
      setScoreTeam1(prediction.scoreTeam1);
      setScoreTeam2(prediction.scoreTeam2);
      setPenaltiesWinner(prediction.penaltiesWinner);
    }
    setModalData({ game, prediction });
    setModalOpen(true);
  };

  const handleStatsModal = async game => {
    const response = await actions.tournaments.getStats(game.id);
    setStatsModalData({
      game,
      stats: response.tournaments.stats[game.id]
        ? response.tournaments.stats[game.id]
        : { team1: 0, draw: 0, team2: 0 },
    });
    setStatsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalData({ game: {}, prediction: {} });
    setScoreTeam1(null);
    setScoreTeam2(null);
    setPenaltiesWinner(null);
    setModalOpen(false);
  };

  const handleCloseStatsModal = () => {
    setModalData({ game: {}, stats: {} });
    setStatsModalOpen(false);
  };

  const handleChangeScoreTeam1 = value => {
    setScoreTeam1(value);
  };

  const handleChangeScoreTeam2 = value => {
    setScoreTeam2(value);
  };

  const handleChangePenaltiesWinner = value => {
    setPenaltiesWinner(value);
  };

  const evaluatePenalties = () => {
    if (modalData.game.hasPenalties && scoreTeam1 === scoreTeam2 && penaltiesWinner) {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (
      scoreTeam1 >= 0 &&
      scoreTeam2 >= 0 &&
      (!modalData.game.hasPenalties ||
        (modalData.game.hasPenalties && scoreTeam1 !== scoreTeam2) ||
        (modalData.game.hasPenalties &&
          scoreTeam1 === scoreTeam2 &&
          (penaltiesWinner === modalData.game.team1.name ||
            penaltiesWinner === modalData.game.team2.name)))
    ) {
      if (
        !modalData.prediction ||
        scoreTeam1 !== modalData.prediction.scoreTeam1 ||
        scoreTeam2 !== modalData.prediction.scoreTeam2 ||
        penaltiesWinner !== modalData.prediction.penaltiesWinner
      ) {
        await actions.misc.setLoading(true);

        const data = {
          scoreTeam1,
          scoreTeam2,
          game: modalData.game.game,
          tournament: modalData.game.tournament,
          penaltiesWinner:
            modalData.game.hasPenalties && scoreTeam1 === scoreTeam2 && penaltiesWinner
              ? penaltiesWinner
              : 'FALSE',
          group: activeGroup.id,
        };
        const id = modalData.prediction && modalData.prediction.id ? modalData.prediction.id : null;
        await actions.predictions.createOrUpdatePrediction(data, id);
      }
      setModalData({ game: {}, prediction: {} });
      setScoreTeam1(null);
      setScoreTeam2(null);
      setPenaltiesWinner(null);
      setModalOpen(false);
    } else {
      actions.misc.setNotification({
        status: true,
        message: 'Por favor completa tu predicción',
        type: 'error',
      });
    }
  };

  const nextGames = useMemo(() => {
    if (
      !tournaments.activeTournament ||
      !tournaments.activeTournament.games ||
      !tournaments.activeTournament.games.length
    )
      return [];
    return tournaments.activeTournament.games
      .filter(game => moment(game.datetime).format() > moment().format())
      .sort((m1, m2) => m1.game - m2.game)
      .slice(0, 5);
  }, [tournaments.activeTournament]);

  const lastGames = useMemo(() => {
    if (!tournaments.activeTournament || !tournaments.activeTournament.games) return [];
    return tournaments.activeTournament.games
      .filter(game => moment(game.datetime).format() < moment().format())
      .sort((m1, m2) => m2.game - m1.game)
      .slice(0, 5);
  }, [tournaments.activeTournament]);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12} md={6} className={classes.grid}>
        <Typography className={classes.sectionTitle} noWrap>
          Próximos Juegos
        </Typography>
        {nextGames.length ? (
          <Grid className={classes.scrollGrid}>
            {nextGames.map((game, index) => (
              <GameCard
                game={game}
                key={index}
                prediction={
                  predictions && predictions[tournaments.activeTournament.code]
                    ? predictions[tournaments.activeTournament.code].find(
                        prediction => prediction.gameId === game.id
                      )
                    : null
                }
                handleEdit={handleEditModal}
                handleStats={handleStatsModal}
              />
            ))}
          </Grid>
        ) : (
          <Grid xs={12} item className={classes.noTournamentsGrid}>
            <Typography className={classes.noTournamentsText}>No hay próximos juegos</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={6} className={classes.grid}>
        <Typography className={classes.sectionTitle} noWrap>
          Últimos Juegos
        </Typography>

        {lastGames.length ? (
          <Grid className={classes.scrollGrid}>
            {lastGames.map((game, index) => {
              return (
                <GameCard
                  game={game}
                  key={index}
                  finished={true}
                  prediction={
                    predictions && predictions[tournaments.activeTournament.code]
                      ? predictions[tournaments.activeTournament.code].find(
                          prediction => prediction.gameId === game.id
                        )
                      : null
                  }
                  handleStats={handleStatsModal}
                />
              );
            })}
          </Grid>
        ) : (
          <Grid xs={12} item className={classes.noTournamentsGrid}>
            <Typography className={classes.noTournamentsText}>No hay juegos pasados</Typography>
          </Grid>
        )}
      </Grid>
      <Modal
        status={modalOpen}
        handleStatus={handleCloseModal}
        message={
          modalData.game.id ? (
            <PredictionModalContent
              game={modalData.game}
              prediction={modalData.prediction}
              scoreTeam1={scoreTeam1}
              scoreTeam2={scoreTeam2}
              penaltiesWinner={penaltiesWinner}
              setScoreTeam1={handleChangeScoreTeam1}
              setScoreTeam2={handleChangeScoreTeam2}
              setPenaltiesWinner={handleChangePenaltiesWinner}
            />
          ) : (
            <></>
          )
        }
        buttonLeftText="Cancelar"
        buttonRightText="Guardar"
        handleRightButton={handleSubmit}
      />
      <Modal
        status={statsModalOpen}
        handleStatus={handleCloseStatsModal}
        message={
          statsModalData.game.id ? (
            <StatsModalContent game={statsModalData.game} stats={statsModalData.stats} />
          ) : (
            <></>
          )
        }
        buttonLeftText="Cerrar"
      />
    </Grid>
  );
};

export { TournamentHome };
