import React from 'react';
import Lottie from 'react-lottie';
import { Box } from '@material-ui/core';

import animation from './animation.json';
import { useStyles } from './styles';

const Done = ({ containerHeight = '50vh', animationHeight = 200, animationWidth = 200 }) => {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Lottie options={defaultOptions} height={animationHeight} width={animationWidth} />
    </Box>
  );
};

export { Done };
