import api, { request } from 'api';
import { save } from 'utils/storage';

export const authActions = {
  login: state => async data => {
    const response = await request.post(api.login, data);
    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message[0].messages[0].message,
            type: 'error',
          },
        },
      };
    }

    const { jwt, user } = response;
    const auth = { token: jwt, isAuthenticated: true, user };
    save('auth', auth);

    return {
      ...state,
      auth,
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  googleLoginCallback: state => async search => {
    const response = await request.get(api.googleLoginCallback(search));
    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message[0].messages[0].message,
            type: 'error',
          },
        },
      };
    }

    const { jwt, user } = response;
    const auth = { token: jwt, isAuthenticated: true, user };
    save('auth', auth);

    return {
      ...state,
      auth,
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  forgot: state => async email => {
    const response = await request.post(api.forgot, { email });

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: 'Something went wrong. Please try again later or contact us',
            type: 'error',
          },
        },
      };
    }

    return {
      ...state,
      misc: {
        ...state.misc,
        loading: false,
        notification: {
          status: true,
          message:
            'Si el email existe en nuestros registros, te llegará un email con las instrucciones!',
          type: 'success',
        },
      },
    };
  },
  reset: state => async data => {
    const response = await request.post(api.reset, data, true);

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: 'Something went wrong with the reset. Please try again.',
            type: 'error',
          },
        },
      };
    }

    return {
      ...state,
      loading: false,
      notification: {
        status: true,
        message: 'Your password has successfully changed',
        type: 'success',
      },
    };
  },
  register: state => async data => {
    const response = await request.post(api.register, data, true);

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message[0].messages[0].message,
            type: 'error',
          },
        },
      };
    }

    const { jwt, user } = response;
    const auth = { token: jwt, isAuthenticated: true, user };
    persistData(auth);

    return {
      ...state,
      auth,
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  logout: () => async () => {
    localStorage.clear();
    
    return {
      auth,
    };
  },
  me: state => async () => {
    const { token } = state.auth;

    const response = await request.get(api.me);

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message[0].messages[0].message,
            type: 'error',
          },
        },
      };
    }

    const auth = { token, isAuthenticated: true, user: response };
    save('auth', auth);

    return {
      ...state,
      auth,
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: '' },
      },
    };
  },
  updateProfilePicture: state => async profilePicture => {
    const { user } = state.auth;
    let formData = new FormData();

    if (profilePicture) {
      formData.append(`files.avatar`, profilePicture, profilePicture.name);
      formData.append('data', JSON.stringify({}));
    } else {
      formData.append('data', JSON.stringify({ avatar: null }));
    }

    const response = await request.put(api.profile(user.profiles[0].id), formData, true, true);

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message[0].messages[0].message,
            type: 'error',
          },
        },
      };
    }

    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        profiles: [response],
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };
    save('auth', auth);

    return {
      auth: updatedProfile,
      misc: {
        ...state.misc,
        loading: false,
        notification: {
          status: true,
          message: 'Perfíl actualizado correctamente!',
          type: 'success',
        },
      },
    };
  },
  updateProfile: state => async data => {
    const { user } = state.auth;

    const response = await request.put(api.profile(user.profiles[0].id), data, true);

    if (response.error) {
      return {
        ...state,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message[0].messages[0].message,
            type: 'error',
          },
        },
      };
    }

    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        profiles: [response],
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };
    save('auth', auth);

    return {
      auth: updatedProfile,
      groups: { activeGroup: undefined, all: [], featured: [], search: [], userGroups: [] },
      misc: {
        ...state.misc,
        loading: false,
        notification: {
          status: true,
          message: 'Perfíl actualizado correctamente!',
          type: 'success',
        },
      },
    };
  },
};
