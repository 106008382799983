import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      alignItems: 'center',
      backgroundColor: colors.black(0.7),
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100vw',
      zIndex: 9999,
    },
  })
);
