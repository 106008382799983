import { Link } from 'react-router-dom';
import { Grid, Paper, Typography } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import React, { useRef } from 'react';

import lqImage from 'assets/images/wired/lq.png';
import { checkHorizontalScrollEnd } from 'utils/helpers';

import useStyles from './styles';

const GroupList = ({ groups, title, onReloadScroll, showLength }) => {
  const classes = useStyles();
  const scrollRef = useRef();

  const onScroll = e => {
    const isHorizontalScrollEnd = checkHorizontalScrollEnd(e);
    if (isHorizontalScrollEnd && onReloadScroll) {
      onReloadScroll();
    }
  };

  return (
    <Grid container className={classes.root}>
      <Typography className={classes.title}>
        {title}
        <Typography className={classes.length}>
          {showLength ? `(${groups.length})` : null}
        </Typography>
      </Typography>
      <Grid innerRef={scrollRef} onScroll={onScroll} item className={classes.groupsGrid}>
        {groups && groups.length ? (
          groups.map((group, index) => (
            <Grid key={index} className={classes.itemGroup}>
              <Link
                className={classes.linkButton}
                to={{ pathname: `/${group.slug}`, state: { group } }}
              >
                <Paper className={classes.coverGrid}>
                  <div
                    style={{
                      backgroundImage: `url(${group.avatar ? group.avatar.url : lqImage})`,
                      backgroundSize: group.avatar ? '100%' : '70%',
                    }}
                    className={classes.cover}
                  />
                </Paper>
                <Typography className={classes.groupTitle}>
                  {group.name ? group.name : group.groupName}
                </Typography>
                {!!group.subscriptions && (
                  <Typography className={classes.groupInstructor}>
                    <GroupIcon className={classes.icon} />
                    {group.subscriptions}{' '}
                    {group.subscriptions > 1 ? 'Jugadores Inscritos' : 'Jugador Inscrito'}
                  </Typography>
                )}
                <Typography className={classes.groupDetails}>Ver detalles</Typography>
              </Link>
            </Grid>
          ))
        ) : (
          <Grid xs={12} item className={classes.noGroupsGrid}>
            <Typography className={classes.noGroupsText}>No hay grupos en esta lista</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { GroupList };
