import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ctx } from '../store';

export const PrivateRoute = ({ children, ...rest }) => {
  const store = useContext(ctx.store);
  const { isAuthenticated } = store.auth;

  return (
    <Route
      {...rest}
      render={() => (isAuthenticated ? children : <Redirect to={{ pathname: '/login' }} />)}
    />
  );
};

export const AnonRoute = ({ children, ...rest }) => {
  const store = useContext(ctx.store);
  const { isAuthenticated } = store.auth;

  return (
    <Route
      {...rest}
      render={() =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/home',
            }}
          />
        )
      }
    />
  );
};
