import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    notificationView: {
      position: 'absolute',
      top: 0,
      width: '100vw',
    },
  })
);
