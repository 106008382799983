// import { IRule } from 'components/RuleGenerator/RuleGenerator';
// import { Timer } from 'hooks/useCountDown';
// import moment from 'moment';
// import { RRule } from 'rrule';
// import { spanish } from './rulesTraslation';

export const checkBottomScroll = () => {
  const scrolled = document.scrollingElement.scrollTop;
  const scrollHeight = document.documentElement.scrollHeight;
  const newHeigh = scrollHeight / 2;
  const newScrolled = scrolled + 200;
  if (newScrolled < newHeigh) {
    return true;
  }
  return false;
};

export const checkHorizontalScrollEnd = e => {
  const element = e.currentTarget;
  const scrollLeft = element.scrollLeft;
  const clientWidth = element.clientWidth;
  const scrollWidth = element.scrollWidth;
  if (scrollWidth === scrollLeft + clientWidth) {
    return true;
  }
  return false;
};

// /* Parse query string to elastic search */
// export const parseQueryParams = (str: string): string => {
//   const currentStrings = str.split(' ').join('+')
//   return currentStrings
//     .normalize('NFD')
//     .replace(/[\u0300-\u036f]/g, '')
//     .toLowerCase()
// }

// export const getCountDownText = (timer: Timer): string => {
//   if (timer.days !== '0') {
//     return `${timer.days} ${timer.days === '1' ? 'Dia' : 'Dias'}`
//   }
//   if (timer.days === '0' && timer.hours !== '0') {
//     return `${timer.hours} ${timer.hours === '1' ? 'Hora' : 'Horas'}`
//   }
//   if (timer.days === '0' && timer.hours === '0' && timer.minutes !== '0') {
//     return `${timer.minutes} min`
//   }
//   return ''
// }

// export const getParseDate = (date: Moment): string => {
//   const month: string | undefined = spanish.monthNames.find(
//     (_: string, i: number) => i == date.month()
//   )
//   return `${date.format('DD')} de ${month} ${date.format('YYYY')}`
// }

// /* Calculate the discount information */
// export const getDiscountInformation = (originalPrice: number, price: number): number => {
//   const original = originalPrice
//   const substractAmount = price
//   if (substractAmount > 0 && original > substractAmount) {
//     const substract = original - substractAmount
//     const discount = (substract / original) * 100
//     return discount
//   } else {
//     return 0
//   }
// }

// export const checkRouteKids = (): boolean => {
//   const host = window.location.hostname.split('.')
//   if (host.length > 0 && host[0] === 'kids') {
//     return true
//   } else {
//     return false
//   }
// }

// const parseRule = (currentRule: IRule) => {
//   const value = RRule.fromString(currentRule.recurrence)
//   const until = moment.utc(value.origOptions.until)
//   const day = moment.utc(value.origOptions.dtstart).utcOffset(0)
//   const options = []
//   if (value.options.byweekday)
//     while (until.isAfter(day)) {
//       if (value.options.byweekday.includes((day.day() + 6) % 7)) options.push(day.format())
//       day.add(1, 'days').format()
//     }
//   return options
// }

// export const getNextDate = (rules: IRule[]): string | null => {
//   if (!rules) return null
//   const allDates = rules
//     .reduce((x: string[], y: IRule) => {
//       return x.concat(parseRule(y))
//     }, [])
//     .sort()

//   for (let i = 0; i < allDates.length; i++)
//     if (moment.utc(allDates[i]) >= moment.utc()) return allDates[i]
//   return null
// }
