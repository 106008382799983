import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Avatar, Button, Container, Grid, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// import { profilePictureFileSizeError, profilePictureMaxSize } from 'utils/messages';

import useStyles from './styles';

// Maximum file size
const maxFileSize = 10000000;

/**
 * Component to upload Profile picture
 *
 * @param {interface IChangeProfilePictureProps} props
 * @returns {JSX.Element}
 */
const ProfilePicture = ({ profilePicture, onUpload }) => {
  // States
  const [preview, setPreview] = useState(profilePicture);

  // Form Control
  const { setError, errors } = useForm();
  const remove = () => {
    if (profilePicture) {
      onUpload(null);
    }
    setPreview(undefined);
  };
  const selectPicture = e => {
    const newPicture = e.target.files[0];
    if (newPicture.size <= maxFileSize) {
      const reader = new FileReader();

      reader.onload = data => {
        if (data.target && data.target.result) {
          setPreview(data.target.result.toString());
        }
      };

      reader.readAsDataURL(newPicture);

      const myRenamedFile = new File([newPicture], newPicture.name, { type: newPicture.type });
      onUpload(myRenamedFile);
    } else {
      setError('picture', { message: 'profilePictureFileSizeError' });
    }
  };
  // Variables
  const classes = useStyles();
  // Component
  return (
    <Grid item container style={{ padding: '0 0 24px 0', justifyContent: 'center' }}>
      <Grid item container className={classes.avatarContainer}>
        <Container className={classes.uploadAvatar}>
          <Container className={classes.avatarImageContainer}>
            <Avatar
              alt="Profile picture preview"
              className={classes.avatar}
              src={preview}
            />
            <IconButton aria-label="upload-button" className={classes.chooseFileButton}>
              <input
                accept=".png,.jpg,.jpeg"
                // ref={register}
                name="picture"
                type="file"
                onChange={e => selectPicture(e)}
                multiple={false}
                data-testid="upload-input"
              />
              <div className={classes.editButton}>
                <EditIcon />
              </div>
            </IconButton>
          </Container>
          <div className={classes.bottomMessage}>
            {(profilePicture || preview) && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.removePhoto}
                onClick={remove}
              >
                Borrar
              </Button>
            )}
            <Typography variant="caption" color={errors && errors.picture && 'error'}>
              {/* {errors && errors.picture && (errors.picture.message || profilePictureMaxSize)} */}
            </Typography>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
};
export { ProfilePicture };
