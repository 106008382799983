import { createStyles, makeStyles } from '@material-ui/core/styles';

import background from 'assets/images/team-background.jpg';
import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flex: 1,
      height: '100vh',
      position: 'fixed',
      width: '100%',
    },
  })
);
