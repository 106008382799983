import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/More';
import NotificationsIcon from '@material-ui/icons/Notifications';

import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cup from 'assets/images/sidebar/overview.png';
import logo from 'assets/images/wired/lq.png';

import { ctx } from 'store';

import { useStyles } from './styles';

const Header = ({
  hideTitle,
  showMenu = false,
  tournament,
  group,
  handleToggleSidebar,
  sidebarToggled,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    auth: { user },
  } = useContext(ctx.store);
  const actions = useContext(ctx.actions);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    await actions.auth.logout();
    history.push('/');
  };

  const goToProfile = async () => {
    history.push('/profile');
  };

  // const goToGroups = async () => {
  //   history.push('/groups');
  // };

  const openTournamentModal = () => {
    actions.misc.seModalState('tournaments', true);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={goToProfile}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*  TODO: FIX ON CLICK ACTIONS */}
      <MenuItem onClick={goToProfile}>
        {/* <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> */}
        <p>Profile</p>
      </MenuItem>
      {/*  TODO: FIX ON CLICK ACTIONS */}
      <MenuItem onClick={handleLogout}>
        {/* <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton> */}
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    // <div className={classes.grow}>
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Hidden mdUp>
          {showMenu && !sidebarToggled && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggleSidebar}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Hidden>
        <Link className={classes.link} to="/home">
          {!hideTitle ? <img src={logo} className={classes.title} /> : <></>}
        </Link>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          {tournament && (
            <Button className={classes.button}>
              <Link className={classes.buttonLink} to={`/${group.slug}`}>
                {tournament.name}
              </Link>
            </Button>
          )}
          {/* {group && (
            <Button onClick={goToGroups} className={classes.button}>
              {group.name}
            </Button>
          )} */}
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            {user &&
            user.profiles.length &&
            user.profiles[0].avatar &&
            user.profiles[0].avatar.url ? (
              <Avatar
                alt="Remy Sharp"
                src={
                  user &&
                  user.profiles.length &&
                  user.profiles[0].avatar &&
                  user.profiles[0].avatar.url
                }
              />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          {/* <IconButton aria-label="show 2 new notifications" color="inherit">
            <Badge badgeContent={2} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          {tournament && (
            <Button className={classes.button}>
              <Link className={classes.buttonLink} to={`/${group.slug}`}>
                {tournament.name}
              </Link>
            </Button>
          )}
          {/* {group && (
            <Button onClick={goToGroups} className={classes.button}>
              {group.name}
            </Button>
          )} */}
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            {user &&
            user.profiles.length &&
            user.profiles[0].avatar &&
            user.profiles[0].avatar.url ? (
              <Avatar
                alt="Remy Sharp"
                src={
                  user &&
                  user.profiles.length &&
                  user.profiles[0].avatar &&
                  user.profiles[0].avatar.url
                }
              />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </AppBar>
    // </div>
  );
};

export { Header };
