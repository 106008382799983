import { Grid, Input, MenuItem, Radio, Select, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import EditIcon from 'assets/images/draw.png';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import { goals } from 'utils/scores.js';

import { useStyles } from './styles';

const PredictionModalContent = ({
  game,
  setScoreTeam1,
  setScoreTeam2,
  setPenaltiesWinner,
  scoreTeam1,
  scoreTeam2,
  penaltiesWinner,
}) => {
  const classes = useStyles();

  const handleChangeScoreTeam1 = event => {
    setScoreTeam1(event.target.value);
  };

  const handleChangeScoreTeam2 = event => {
    setScoreTeam2(event.target.value);
  };

  const handleChangePenaltiesWinner = event => {
    setPenaltiesWinner(event.target.value);
  };

  const selectMenu = () => {
    return goals.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <Grid className={classes.paper}>
      <Typography className={classes.number} noWrap>
        {`Partido ${game.game}`}
      </Typography>
      <Typography className={classes.phase} noWrap>
        {game.phase.name}
      </Typography>
      <Typography className={classes.datePlace} noWrap>
        {moment(game.datetime).format('ddd DD MMM YYYY - HH:mm')} {'- '}
        {game.stadium.city}
      </Typography>
      <div className={classes.details}>
        <div className={classes.teamLeft}>
          <div className={classes.team}>
            <img src={game.team1.flag ? game.team1.flag.url : null} className={classes.flag} />
            <Typography className={classes.teamName} noWrap>
              {game.team1.name}
            </Typography>
            <Select
              select
              value={scoreTeam1}
              onChange={handleChangeScoreTeam1}
              className={classes.inputs}
              InputProps={{
                classes: {
                  root: classes.inputProps,
                },
              }}
              input={
                <Input
                  classes={{
                    underline: classes.underline,
                  }}
                />
              }
              IconComponent={() => <img src={EditIcon} className={classes.edit} />}
            >
              {selectMenu()}
            </Select>
          </div>
          <div className={classes.team}>
            <img src={game.team2.flag ? game.team2.flag.url : null} className={classes.flag} />
            <Typography className={classes.teamName} noWrap>
              {game.team2.name}
            </Typography>
            <Select
              select
              variant="standard"
              value={scoreTeam2}
              className={classes.inputs}
              InputProps={{
                classes: {
                  root: classes.inputProps,
                },
              }}
              input={
                <Input
                  classes={{
                    underline: classes.underline,
                  }}
                />
              }
              onChange={handleChangeScoreTeam2}
              IconComponent={() => <img src={EditIcon} className={classes.edit} />}
            >
              {selectMenu()}
            </Select>
          </div>
        </div>
      </div>
      {game.hasPenalties && scoreTeam1 === scoreTeam2 ? (
        <Grid className={classes.penaltiesGrid}>
          <Typography className={classes.datePlace} noWrap>
            Selecciona el ganador en los penales
          </Typography>
          <Grid className={classes.radioGrid}>
            <Radio
              checked={penaltiesWinner === game.team1.name}
              onChange={handleChangePenaltiesWinner}
              value={game.team1.name}
              icon={<SportsSoccerIcon />}
              checkedIcon={<SportsSoccerIcon />}
            />
            <Radio
              checked={penaltiesWinner === game.team2.name}
              onChange={handleChangePenaltiesWinner}
              value={game.team2.name}
              icon={<SportsSoccerIcon />}
              checkedIcon={<SportsSoccerIcon />}
            />
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export { PredictionModalContent };
