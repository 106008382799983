import MomentUtils from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ContextProvider } from '@talpor/react-context-manager';
import moment from 'moment';
import React, { useEffect } from 'react';
import OneSignal from 'react-onesignal';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Notification from './components/Notification';
import Loader from './components/Loader';
import './index.css';
import GroupCreation from './scenes/GroupCreation';
import GroupDashboard from './scenes/GroupDashboard';
import Login from './scenes/Login';
import Groups from './scenes/Groups';
// import Home from './scenes/Home';
import Profile from './scenes/Profile';
import Register from './scenes/Register';
import Search from './scenes/Search';
import Forgot from './scenes/Forgot';
import Handler from './scenes/Handler';
import Reset from './scenes/Reset';
import { actions, ctx, store } from './store';
import theme from './styles';
import { AnonRoute, PrivateRoute } from './utils/routes';

import 'moment/locale/es'; // without this line it didn't work

const ONESIGNAL_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
const GTM_ID = process.env.REACT_APP_GTM;

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const App = () => {
  useEffect(() => {
    moment.locale('es');
    OneSignal.init({
      appId: ONESIGNAL_ID,
    });
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      <ContextProvider actions={actions} store={store} context={ctx}>
        <Loader />
        <Notification />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <AnonRoute path="/login">
                <Login />
              </AnonRoute>
              <AnonRoute path="/register">
                <Register />
              </AnonRoute>
              <AnonRoute path="/forgot">
                <Forgot />
              </AnonRoute>
              <AnonRoute path="/handler">
                <Handler />
              </AnonRoute>
              <AnonRoute path="/reset">
                <Reset />
              </AnonRoute>
              <PrivateRoute path="/home">
                <Groups />
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute path="/create">
                <GroupCreation />
              </PrivateRoute>
              <PrivateRoute path="/search">
                <Search />
              </PrivateRoute>
              <PrivateRoute path="/:groupId">
                <GroupDashboard />
              </PrivateRoute>
              <Route
                path="/"
                component={() => {
                  window.location.href = '/login';
                  return null;
                }}
              />
            </Switch>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </ContextProvider>
    </Router>
  );
};

export default App;
