import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import TournamentCard from 'components/TournamentCard';
import { ctx } from 'store';

import { useStyles } from './styles';

const TournamentsSelection = ({ activeGroup }) => {
  const {
    tournaments: { currentTournaments, pastTournaments },
  } = useContext(ctx.store);
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.grid}>
      <Paper className={classes.tournamentsPaper}>
        <Typography className={classes.modalTitle} noWrap>
          Selecciona el Torneo
        </Typography>
        <Typography className={classes.sectionTitle} noWrap>
          Torneos Activos
        </Typography>
        <Grid className={classes.tournamentList}>
          {currentTournaments && currentTournaments.length ? (
            currentTournaments.map((tournament, index) => (
              <TournamentCard key={index} tournament={tournament} activeGroup={activeGroup} />
            ))
          ) : (
            <Grid xs={12} item className={classes.noTournamentsGrid}>
              <Typography className={classes.noTournamentsText}>No hay torneos actuales</Typography>
            </Grid>
          )}
        </Grid>
        <Typography className={classes.sectionTitle} noWrap>
          Torneos Pasados
        </Typography>
        <Grid className={classes.tournamentList}>
          {pastTournaments && pastTournaments.length ? (
            pastTournaments.map((tournament, index) => (
              <TournamentCard key={index} tournament={tournament} activeGroup={activeGroup} />
            ))
          ) : (
            <Grid xs={12} item className={classes.noTournamentsGrid}>
              <Typography className={classes.noTournamentsText}>
                No existen torneos pasados
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export { TournamentsSelection };
