import { createStyles, makeStyles } from '@material-ui/core/styles';

import background from 'assets/images/background.png';
import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      height: 'calc(100% - 100px)',
      marginTop: 110,
      maxWidth: 'initial',
      overflowX: 'hidden',
      overflowY: 'scroll',
      width: '100vw',
      zIndex: 500,
      [theme.breakpoints.down('md')]: {
        left: 0,
      },
    },
    root: {
      background: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      position: 'fixed',
      width: '100%',
      height: '100vh',
      // [theme.breakpoints.down('md')]: {
      //   display: 'block',
      // },
    },
    image: {
      background: `url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      width: '100%',
      height: '100vh',
      position: 'absolute',
    },
  })
);
