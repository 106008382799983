import api, { request } from 'api';
import moment from 'moment';
import queryString from 'query-string';

import { get, save } from 'utils/storage';

export const tournamentsActions = {
  getTournaments: state => async () => {
    const tournaments = get('tournaments');
    let activeTournament = get('activeTournament');
    activeTournament = activeTournament ? activeTournament.activeTournament : undefined;

    if (tournaments) {
      const query = queryString.stringify({ list: 'current' });
      const response = await request.get(api.tournaments(query));

      if (response.error) {
        return {
          misc: {
            notification: {
              status: true,
              message: response.error.body.message,
              type: 'error',
            },
          },
        };
      }

      const pastTournaments = tournaments.pastTournaments;
      const currentTournaments = response.map(element => {
        if (
          tournaments.currentTournaments.map(tournament => tournament.code).includes(element.code)
        ) {
          element.games = tournaments.currentTournaments.find(
            tournament => tournament.code === element.code
          ).games;
        }

        return element;
      });

      save('tournaments', { currentTournaments, pastTournaments });

      return {
        tournaments: {
          currentTournaments: response,
          fetched: true,
          pastTournaments: tournaments.pastTournaments,
          activeTournament,
        },
        misc: {
          ...state.misc,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }

    const response = await request.get(api.tournaments());

    if (response.error) {
      return {
        misc: {
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const pastTournaments = response.filter(tournament => tournament.isFinished);
    const currentTournaments = response.filter(tournament => !tournament.isFinished);

    save('tournaments', { currentTournaments, pastTournaments });

    return {
      tournaments: {
        currentTournaments,
        fetched: true,
        pastTournaments,
        activeTournament,
      },
      misc: {
        ...state.misc,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  setActiveTournament: state => async (activeTournament, tournaments) => {
    const tournamentsState = tournaments ? tournaments : state.tournaments;
    return {
      tournaments: {
        ...tournamentsState,
        activeTournament,
      },
    };
  },
  getGames: state => async (tournamentCode, tournaments) => {
    const checkpoint = get(`checkpoint${tournamentCode}`);
    const evaluatedTournaments = tournaments ? tournaments : state.tournaments;

    let tournamentType = 'pastTournaments';
    let tournamentIndex = evaluatedTournaments.pastTournaments.findIndex(
      tournament => tournament.code === tournamentCode
    );
    if (tournamentIndex < 0) {
      tournamentType = 'currentTournaments';
      tournamentIndex = evaluatedTournaments.currentTournaments.findIndex(
        tournament => tournament.code === tournamentCode
      );
    }

    const tournamentsToBeModified =
      tournamentType === 'currentTournaments'
        ? evaluatedTournaments.currentTournaments
        : evaluatedTournaments.pastTournaments;

    if (
      !checkpoint ||
      moment(checkpoint).toDate() <
        moment(tournamentsToBeModified[tournamentIndex].reviewedDate).toDate()
    ) {
      const response = await request.get(api.games(tournamentCode));

      if (response.error) {
        return {
          misc: {
            notification: {
              status: true,
              message: response.error.body.message,
              type: 'error',
            },
          },
        };
      }

      tournamentsToBeModified[tournamentIndex].games = response;

      const tournamentsState = {
        ...evaluatedTournaments,
        [tournamentType]: tournamentsToBeModified,
      };

      save('tournaments', {
        currentTournaments: tournamentsState.currentTournaments,
        pastTournaments: tournamentsState.pastTournaments,
      });
      save(`checkpoint${tournamentCode}`, moment().format());

      return {
        tournaments: tournamentsState,
        misc: {
          ...state.misc,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    } else {
      return {
        tournaments: evaluatedTournaments,
        misc: {
          ...state.misc,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }
  },
  getStats: state => async (gameId) => {
    const query = queryString.stringify({ gameId });
    const response = await request.get(api.statistics(query));
    
    if (response.error) {
      return {
        misc: {
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    return {
      tournaments: {
        ...state.tournaments,
        stats: {
          ...state.tournaments.stats,
          [gameId]: response,
        },
      },
      misc: {
        ...state.misc,
        notification: { status: false, message: '', type: 'success' },
      },
    };    
  }
};
