import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    adminContent: {
      borderTop: `1px solid ${colors.white(0.1)}`,
      margin: theme.spacing(2, 0),
    },
    avatar: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 50,
      height: 100,
      width: 100,
    },
    avatarGrid: {
      background: `linear-gradient(to left, ${colors.black(0.6)}, ${colors.black(0.6)})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 50,
      height: 100,
      width: 100,
    },
    button: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
      margin: theme.spacing(1, 0, 0),
    },
    collapsed: {
      alignSelf: 'center',
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      width: 270,
    },
    formControlLabel: {
      color: 'white',
    },
    footerSideBar: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    headerSideBar: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    image: {
      filter: 'brightness(1) contrast(300%) invert(1)',
      height: '60%',
      width: '60%',
    },
    imageCt: {
      height: '100%',
      width: '100%',
      borderRadius: '50%',
    },
    logoContainer: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    menuTitle: {
      color: colors.lqOrange,
      fontSize: '1rem',
      margin: theme.spacing(1, 3),
    },
    playerValue: {
      color: colors.lqOrange,
      fontSize: '1.1rem',
      fontWeight: 'bold',
      margin: theme.spacing(0),
      textAlign: 'left',
    },
    sidebar: {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      zIndex: 999,
      [theme.breakpoints.down('md')]: {
        backgroundColor: colors.black(0.85),
      },
    },
    subMenu: {
      width: 270,
    },
    textStatistics: {
      color: colors.white(1),
      fontSize: '1rem',
    },
    textTournament: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2rem',
      lineHeight: 1,
      margin: theme.spacing(1, 0),
      textAlign: 'center',
      width: '80%',
    },
  })
);
