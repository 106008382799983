import { createStyles, makeStyles } from '@material-ui/core/styles';

import background from 'assets/images/team-background.jpg';
import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      fontWeight: 'bold',
      margin: theme.spacing(2, 0),
      minHeight: 50,
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(6),
      },
    },
    form: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20,
      width: '40%', // Fix IE 11 issue.
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(0),
        width: '90%', // Fix IE 11 issue.
      },
    },
    groupStateLabel: {
      color: colors.white(1),
      fontSize: '1rem',
      lineHeight: 1,
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
        width: '90%',
      },
    },
    inputLabels: {
      color: colors.white(1),
      fontSize: '1rem',
      marginBottom: -5,
      marginTop: 5,
      width: '100%',
    },
    inputProps: {
      border: `1px solid ${colors.white(1)}`,
      color: colors.white(1),
      fontWeight: 'bold',
    },
    inputPropsDisabled: {
      color: colors.white(1),
    },
    inputs: {
      backgroundColor: colors.white(0.2),
      color: colors.white(1),
      fontFamily: 'Poppins',
      margin: theme.spacing(1, 0),
      width: '100%', // Fix IE 11 issue.
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      overflowY: 'scroll',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        justifyContent: 'flex-start',
      },
    },
    root: {
      background: `linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flex: 1,
      height: '100vh',
      position: 'fixed',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: 120,
      },
    },
    subtitle: {
      color: colors.white(0.9),
      fontSize: '1rem',
      margin: theme.spacing(0, 0, 3),
      textAlign: 'center',
      width: '80%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '0.9rem',
        margin: theme.spacing(1),
        width: '90%',
      },
    },
    title: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2.4rem',
      margin: theme.spacing(6, 2, 0),
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2rem',
        lineHeight: 1,
        margin: theme.spacing(1),
        width: '96%',
      },
    },
  })
);
