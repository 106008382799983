import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { ContactSupportOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { ctx } from 'store';

import { useStyles } from './styles';

const StepOne = props => {
  const classes = useStyles();
  const { errors, handleSubmit, register, watch } = useForm();
  const actions = useContext(ctx.actions);

  const onSubmit = async data => {
    if (!errors) {
      await actions.misc.setLoading(true);
      const response = await actions.groups.createGroup({ ...data });
      if (response.misc.notification.type === 'success') {
        props.nextStep();
      }
    }
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.paper}>
        <Typography className={classes.title}>
          Validemos el nombre del grupo que deseas crear
        </Typography>
        <Typography className={classes.subtitle}>
          Si el nombre esta libre, crearemos el grupo y luego te pediremos los demás datos
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Typography className={classes.inputLabels}>Nombre del Grupo</Typography>
          <TextField
            {...register('name', { required: true })}
            variant="outlined"
            placeholder="Mi Grupo"
            name="name"
            className={classes.inputs}
            InputProps={{
              classes: {
                root: classes.inputProps,
                disabled: classes.inputPropsDisabled,
              },
            }}
            inputProps={{ maxLength: 60 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={!watch('name') || !watch('name').length}
          >
            Guardar
          </Button>
        </form>
      </div>
    </Grid>
  );
};

export { StepOne };
