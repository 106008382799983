import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Container } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import { ctx } from 'store';

import { useStyles } from './styles';
import GroupProfile from './GroupProfile';
import GroupHome from './GroupHome';
import Ranking from './Ranking';
import Requests from './Requests';
import TournamentHome from './TournamentHome';
import TournamentPhase from './TournamentPhase';
import TournamentsSelection from './TournamentsSelection';
import Users from './Users';

const GroupDashboard = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { groupId } = useParams();
  const [toggled, setToggled] = useState(false);

  const {
    auth: { user },
    groups: { activeGroup, userGroups, featured, all },
    tournaments,
  } = useContext(ctx.store);
  const actions = useContext(ctx.actions);
  const classes = useStyles();

  useEffect(() => {
    const fetchGroups = async () => {
      let evaluatedGroups = { userGroups, all, featured };
      if (
        !userGroups ||
        !userGroups.length ||
        (userGroups.length === 1 && activeGroup.id === userGroups[0].id)
      ) {
        await actions.misc.setLoading(true);
        const response = await actions.groups.getUserGroups();
        evaluatedGroups = response.groups;
        await actions.misc.setLoading(false);
      }

      if (evaluatedGroups.userGroups.find(group => group.slug === groupId)) {
        await actions.groups.setActiveGroup(
          evaluatedGroups.userGroups.find(group => group.slug === groupId)
        );
      } else if (evaluatedGroups.featured.find(group => group.slug === groupId)) {
        await actions.groups.setActiveGroup(
          evaluatedGroups.featured.find(group => group.slug === groupId)
        );
      } else {
        if (evaluatedGroups.all && evaluatedGroups.all.length) {
          await actions.groups.setActiveGroup(
            evaluatedGroups.all.find(group => group.slug === groupId)
          );
        } else {
          await actions.misc.setLoading(true);
          await actions.groups.getGroups({ slug: groupId });
        }
      }
    };

    if (groupId) {
      fetchGroups();
    } else {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    const fetchTournaments = async () => {
      let evaluatedTournaments = tournaments;

      if (!tournaments.currentTournaments || !tournaments.currentTournaments.length) {
        await actions.misc.setLoading(true);
        const tournamentsResponse = await actions.tournaments.getTournaments();
        evaluatedTournaments = tournamentsResponse.tournaments;
        for (const tournament of tournamentsResponse.tournaments.currentTournaments) {
          const response = await actions.tournaments.getGames(
            tournament.code,
            evaluatedTournaments
          );
          if (response && !response.error) {
            evaluatedTournaments = response.tournaments;
          }
        }
        await actions.misc.setLoading(false);
      }
    };

    if (groupId) {
      fetchTournaments();
    } else {
      history.push('/home');
    }
  }, [activeGroup]);

  // useEffect(() => {
  //   const path = location.pathname;
  //   if (
  //     (path.includes('users') || path.includes('settings') || path.includes('requests')) &&
  //     (!activeGroup || activeGroup.admin !== user.id)
  //   ) {
  //     const splitted = path.split('/');
  //     history.push(`/${splitted[1]}`);
  //   }

  //   if (
  //     (path.includes('ranking') || path.includes('tournament')) &&
  //     (!activeGroup ||
  //       !user.memberships.find(
  //         membership => membership.group === activeGroup.id && membership.accepted
  //       ))
  //   ) {
  //     const splitted = path.split('/');
  //     history.push(`/${splitted[1]}`);
  //   }
  // }, [location.pathname]);

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };

  return (
    <div className={classes.root}>
      <Header
        showMenu={true}
        hideTitle={true}
        tournament={
          activeGroup &&
          user.memberships &&
          user.memberships.length &&
          user.memberships.find(
            membership => membership.group === activeGroup.id && membership.accepted
          )
            ? tournaments.activeTournament
            : null
        }
        group={activeGroup}
        handleToggleSidebar={handleToggleSidebar}
        sidebarToggled={toggled}
      />
      <Sidebar
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        activeGroup={activeGroup}
        activeTournament={tournaments.activeTournament}
      />
      <Container className={classes.container}>
        <Switch>
          <Route exact path={`${match.path}`}>
            <GroupHome activeGroup={activeGroup} />
          </Route>
          <Route exact path={`${match.path}/tournaments`}>
            <TournamentsSelection activeGroup={activeGroup} />
          </Route>
          <Route exact path={`${match.path}/settings`}>
            <GroupProfile />
          </Route>
          <Route exact path={`${match.path}/requests`}>
            <Requests />
          </Route>
          <Route exact path={`${match.path}/users`}>
            <Users />
          </Route>
          <Route exact path={`${match.path}/tournament/:tournamentId`}>
            <TournamentHome />
          </Route>
          <Route exact path={`${match.path}/tournament/:tournamentId/ranking`}>
            <Ranking />
          </Route>
          <Route exact path={`${match.path}/tournament/:tournamentId/phase/:phaseId`}>
            <TournamentPhase />
          </Route>
          <Route path={match.path}>{<Redirect to={`/${location.pathname.split('/')[1]}`} />}</Route>
        </Switch>
      </Container>
    </div>
  );
};

export { GroupDashboard };
