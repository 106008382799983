import { get } from 'utils/storage';

export const initialAuthStore = {
  isAuthenticated: false,
  token: '',
  user: {
    blocked: false,
    confirmed: false,
    createdAt: '',
    email: '',
    id: '',
    provider: '',
    updatedAt: '',
    username: '',
    profiles: [],
    histories: [],
  },
};

export const getAuthStore = () => {
  const savedAuthStore = get('auth');
  if (savedAuthStore) {
    return savedAuthStore;
  }
  return initialAuthStore;
};

export const authStore = getAuthStore();
