import { Button, CssBaseline, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

// import Google from 'assets/images/google.svg';
// import Microsoft from 'assets/images/microsoft.svg';
import Logo from 'assets/images/wired/lqDark.png';
import { ctx } from 'store';
import { validEmailRegex } from 'utils/regex';

import { useStyles } from './styles';

export const API_BASE = process.env.REACT_APP_API_BASE;

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { errors, getValues, handleSubmit, register, reset } = useForm();
  const actions = useContext(ctx.actions);

  const onSubmit = async credentials => {
    await actions.misc.setLoading(true);
    const { misc } = await actions.auth.login(credentials);
    if (misc.notification.type === 'success') {
      history.push('/home');
    } else {
      reset({ ...getValues(), password: '' });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography className={classes.title}>Inicia Sesión en</Typography>
          <img alt="La Quiniela" width="240" src={Logo} style={{ marginBottom: 16 }} />
          {/* <Grid className={classes.socialGrid}>
            <a
              className={classes.slackButton}
              // onClick={() => (window.location = `${API_BASE}/connect/google`)}
            >
              <img alt="Sign in with Google" width="240" src={Google} />
            </a>
            <a
              className={classes.slackButton}
              // onClick={() => (window.location = `${API_BASE}/connect/microsoft`)}
            >
              <img alt="Sign in with Microsoft" width="240" src={Microsoft} />
            </a>
          </Grid> */}
          {/* <Typography className={classes.text}>o ingresa con tu correo electrónico</Typography> */}
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant="outlined"
              {...register('identifier', {
                required: true,
                pattern: {
                  value: validEmailRegex,
                  message: 'Correo electrónico inválido',
                },
              })}
              placeholder="Correo electrónico"
              name="identifier"
              autoFocus
              className={classes.inputs}
            />
            <TextField
              {...register('password', { required: true })}
              variant="outlined"
              placeholder="Contraseña"
              name="password"
              type="password"
              // error={(errors).password}
              className={classes.inputs}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Iniciar Sesión
            </Button>
            <Grid item xs>
              <Link to="/forgot" variant="body2" className={classes.text}>
                ¿Olvidaste tu Contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/register" variant="body2" className={classes.text}>
                ¿No tienes cuenta? Regístrate aquí
              </Link>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export { Login };
