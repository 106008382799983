import humps from 'humps';

export const API_BASE = process.env.REACT_APP_API_BASE;

const api = {
  forgot: `${API_BASE}/auth/forgot-password`,
  games: tournament => `${API_BASE}/games/?tournament=${tournament}`,
  googleLoginCallback: search => `${API_BASE}/auth/google/callback${search}`,
  group: id => `${API_BASE}/groups/${id}`,
  groups: query => (query ? `${API_BASE}/groups/?${query}` : `${API_BASE}/groups`),
  groupSearch: query => `${API_BASE}/groups/search/?${query}`,
  histories: query => (query ? `${API_BASE}/histories/?${query}` : `${API_BASE}/histories`),
  history: id => `${API_BASE}/histories/${id}`,
  login: `${API_BASE}/auth/local/`,
  me: `${API_BASE}/users/me`,
  membership: id => `${API_BASE}/memberships/${id}`,
  memberships: query => (query ? `${API_BASE}/memberships/?${query}` : `${API_BASE}/memberships`),
  membershipSearch: query => `${API_BASE}/memberships/search/?${query}`,
  prediction: id => `${API_BASE}/predictions/${id}`,
  predictions: query => (query ? `${API_BASE}/predictions/?${query}` : `${API_BASE}/predictions`),
  profile: id => `${API_BASE}/profiles/${id}`,
  ranking: query => `${API_BASE}/histories/?${query}`,
  register: `${API_BASE}/auth/local/register`,
  reset: `${API_BASE}/auth/reset-password`,
  statistics: query => `${API_BASE}/statistics?${query}`,
  tournaments: query => (query ? `${API_BASE}/tournaments?${query}` : `${API_BASE}/tournaments`),
  upload: `${API_BASE}/upload`,
  userGroups: `${API_BASE}/groups/user`,
};

export default api;

const getHeaders = async multipart => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const { token } = JSON.parse(localStorage.getItem('auth') || '{}');
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (multipart) {
    delete headers['Content-Type']; // important, form data will not be sent without deleting this
  }

  return headers;
};

const parseBody = (data, literal, multipart) => {
  if (multipart) {
    return data;
  } else if (literal) {
    return JSON.stringify(data);
  } else {
    return JSON.stringify(humps.decamelizeKeys(data));
  }
};

const fetchHelper = async (url, method, data, literal, multipart) => {
  if (url.indexOf('?') < 0) {
    url = url.endsWith('/') ? url : `${url}/`;
  }
  const attributes = {
    headers: await getHeaders(multipart),
    method,
  };

  if (data) {
    attributes.body = parseBody(data, literal, multipart);
  }

  try {
    const response = await fetch(url, attributes);

    if (response.ok) {
      return humps.camelizeKeys(await response.json());
    } else {
      const body = await response.json();
      const message =
        Object.entries(body).length > 0 ? Object.entries(body)[0][1] : response.statusText;

      return { error: { status: response.status, statusText: message, body } };
    }
  } catch (e) {
    return { error: { statusText: e } };
  }
};

export const request = {
  delete: url => {
    return fetchHelper(url, 'DELETE');
  },
  get: url => {
    return fetchHelper(url, 'GET');
  },
  patch: (url, data, literal) => {
    return fetchHelper(url, 'PATCH', data, literal);
  },
  post: (url, data, literal, multipart) => {
    return fetchHelper(url, 'POST', data, literal, multipart);
  },
  put: (url, data, literal, multipart) => {
    return fetchHelper(url, 'PUT', data, literal, multipart);
  },
};
