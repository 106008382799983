import api, { request } from 'api';

import { save } from 'utils/storage';

export const historiesActions = {
  createHistory: state => async tournament => {
    const response = await request.post(
      api.histories(),
      {
        tournament,
        groupId: state.groups.activeGroup.id,
      },
      true
    );

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }
    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        histories: [...state.auth.user.histories, response],
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };

    save('auth', auth);

    return {
      auth,
      misc: {
        ...state.misc,
        loading: false,
      },
    };
  },
};
