import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    grid: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },
    modalTitle: {
      alignSelf: 'center',
      color: colors.white(1),
      fontFamily: 'Teko',
      fontSize: '2.8rem',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.4rem',
        lineHeight: '2.6rem',
        margin: theme.spacing(1, 0),
      },
    },
    noTournamentsGrid: {
      alignItems: 'center',
      display: 'flex',
      height: 200,
      justifyContent: 'center',
    },
    noTournamentsText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    sectionTitle: {
      color: colors.lqOrange,
      display: 'flex',
      fontFamily: 'Teko',
      fontSize: '2.4rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2.4rem',
        lineHeight: '2.6rem',
        margin: theme.spacing(1, 0),
      },
    },
    tournamentList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: theme.spacing(1, 0, 2),
      overflowX: 'scroll',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    tournamentsPaper: {
      border: `0px solid ${colors.lqOrange}`,
      backgroundColor: colors.black(0),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
    },
  })
);
