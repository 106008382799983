import { createStyles, makeStyles } from '@material-ui/core/styles';
import { flash } from 'react-animations';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    '@keyframes flash': flash,
    avatar: {
      margin: theme.spacing(1, 1, 1, 2),
      [theme.breakpoints.down('md')]: {
        height: 36,
        width: 36,
      },
    },
    button: {
      display: 'flex',
      flex: 0.3,
      fontSize: '0.9rem',
      fontWeight: 'bold',
      margin: theme.spacing(0, 0, 0, 2),
    },
    columnFlag: {
      alignItems: 'center',
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flex: 0.5,
      },
    },
    columnPoints: {
      alignItems: 'center',
      flex: 0.7,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        flex: 2,
      },
    },
    columnPosition: {
      alignItems: 'center',
      flex: 0.7,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        flex: 0.5,
      },
    },
    columnUser: {
      alignItems: 'center',
      flex: 2,
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flex: 3,
      },
    },
    flag: {
      height: 18,
      [theme.breakpoints.down('md')]: {
        height: 12,
      },
    },
    headerSearch: {
      display: 'flex',
      flexDirection: 'row',
    },
    headerText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 500,
      margin: theme.spacing(1, 0),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '0.8rem',
      },
    },
    headerTitles: {
      display: 'flex',
      flexDirection: 'row',
    },
    inputPlaceholder: {
      color: colors.white(1),
    },
    inputProps: {
      color: colors.white(1),
    },
    inputs: {
      backgroundColor: colors.white(0.1),
      display: 'flex',
      flex: 1,
      color: colors.white(1),
      margin: theme.spacing(0, 0),
      width: '80%', // Fix IE 11 issue.
    },
    itemText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.2rem',
      fontWeight: 500,
      margin: theme.spacing(1, 2, 1, 0),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '1rem',
        margin: theme.spacing(1, 1, 1, 0),
      },
    },
    itemTextOrange: {
      color: colors.lqOrange,
      display: 'flex',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      margin: theme.spacing(1, 0),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '1.2rem',
      },
    },
    list: {
      margin: '0 20%',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
      },
    },
    listHeader: {
      backgroundColor: colors.black(0.9),
      color: colors.white(1),
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(0),
    },
    listItem: {
      alignItems: 'space-between',
      backgroundColor: colors.white(0.3),
      borderRadius: 5,
      display: 'flex',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0),
    },
    noUsersGrid: {
      alignItems: 'center',
      display: 'flex',
      height: 200,
      justifyContent: 'center',
    },
    noUsersText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    loadingGrid: {
      animation: '$flash 4s infinite',
      backgroundColor: colors.black(0.9),
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.3rem',
      justifyContent: 'center',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '100%',
    },
    rankingTitle: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2.5rem',
      fontWeight: 500,
      margin: theme.spacing(1, 0, 0),
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
  })
);
