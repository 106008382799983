import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    button: {
      fontWeight: 'bold',
      margin: theme.spacing(2, 0),
      minHeight: 50,
      textTransform: 'none',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(6),
      },
    },
    form: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 20,
      width: '40%', // Fix IE 11 issue.
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(0),
        width: '90%', // Fix IE 11 issue.
      },
    },
    groupStateCheck: {
      marginLeft: theme.spacing(1),
    },
    groupStateDiv: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    groupStateLabel: {
      color: colors.white(1),
      fontSize: '1rem',
    },
    inputLabels: {
      color: colors.white(1),
      fontSize: '1rem',
      marginBottom: -5,
      marginTop: 5,
      width: '100%',
    },
    inputProps: {
      border: `1px solid ${colors.white(1)}`,
      color: colors.white(1),
      fontWeight: 'bold',
    },
    inputPropsDisabled: {
      color: colors.white(1),
    },
    inputs: {
      backgroundColor: colors.white(0.2),
      color: colors.white(1),
      fontFamily: 'Poppins',
      margin: theme.spacing(1, 0),
      width: '100%', // Fix IE 11 issue.
    },
    inputSelect: {
      backgroundColor: colors.white(0.2),
      border: `2px solid ${colors.white(1)}`,
      color: colors.white(1),
      fontFamily: 'Poppins',
      lineHeight: '1.4rem',
      margin: theme.spacing(1, 0),
      width: '100%', // Fix IE 11 issue.
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      overflowY: 'scroll',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        justifyContent: 'flex-start',
      },
    },
    root: {
      position: 'fixed',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
      },
    },
    socialGrid: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(3, 0, 0),
      width: '50%',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '80%',
      },
    },
    text: {
      color: colors.lqOrange,
      fontSize: '1rem',
      width: '100%',
    },
    title: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '4rem',
      fontWeight: '500',
      [theme.breakpoints.down('md')]: {
        fontSize: '3rem',
      },
    },
  })
);
