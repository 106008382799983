import { Avatar, Button, Paper, Switch, Typography } from '@material-ui/core';
import AnimatedNumber from 'animated-number-react';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useHistory } from 'react-router-dom';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  // SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
// import 'react-pro-sidebar/dist/css/styles.css';
import 'sidebar.scss';

import details from 'assets/images/sidebar/details.png';
import fixtures from 'assets/images/sidebar/fixtures.png';
import home from 'assets/images/sidebar/home-icon.png';
import lq from 'assets/images/wired/lq.png';
import manage from 'assets/images/sidebar/manage.png';
import overview from 'assets/images/sidebar/overview.png';
import ranking from 'assets/images/sidebar/ranking.png';
import requests from 'assets/images/sidebar/requests.png';
import settings from 'assets/images/sidebar/settings.png';

import { ctx } from 'store';

import { useStyles } from './styles';

export const Sidebar = ({ toggled, handleToggleSidebar, activeGroup, activeTournament }) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const {
    auth: { user },
  } = useContext(ctx.store);
  // const actions = useContext(ctx.actions);

  const calculateHistory = () => {
    if (activeTournament && activeGroup) {
      return user.histories.find(
        history =>
          history.tournament === activeTournament.code && history.groupName === activeGroup.name
      );
    }
    return null;
  };

  const goToGroups = async () => {
    history.push('/home');
  };

  const formatValue = value => value.toFixed(0);

  return (
    <ProSidebar
      collapsed={collapsed}
      className={classes.sidebar}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="lg"
    >
      <SidebarHeader className={classes.headerSideBar}>
        <Paper className={classes.avatarGrid}>
          <div
            style={{
              backgroundImage: `url(${
                activeGroup && activeGroup.avatar ? activeGroup.avatar.url : lq
              })`,
              backgroundSize: activeGroup && activeGroup.avatar ? '100%' : '70%',
            }}
            className={classes.avatar}
          />
        </Paper>
        {/* <Avatar
          alt="group-logo"
          src={activeGroup && activeGroup.avatar ? activeGroup.avatar.url : lq}
          className={classes.avatar}
        /> */}
        {/* <img src={activeGroup ? activeGroup.avatar.url : null} className={classes.avatar} /> */}
        <Typography className={classes.textTournament}>
          {activeGroup ? activeGroup.name : '-'}
        </Typography>
        {activeTournament && (
          <>
            <Typography className={classes.textStatistics}>
              {`Puntos: `}
              {
                <AnimatedNumber
                  formatValue={formatValue}
                  className={classes.playerValue}
                  value={calculateHistory() ? calculateHistory().points : '-'}
                />
              }
            </Typography>
            <Typography className={classes.textStatistics}>
              {`Posición: `}
              {
                <AnimatedNumber
                  formatValue={formatValue}
                  className={classes.playerValue}
                  value={calculateHistory() ? calculateHistory().position : '-'}
                />
              }
              {`${
                activeTournament && activeTournament.participants
                  ? 'de ' + activeTournament.participants
                  : ''
              }`}
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={goToGroups}
        >
          Cambiar Grupo
        </Button>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle" className={classes.content}>
          <MenuItem
            icon={<img alt="sensla pillars" src={details} className={classes.image} />}
            onClick={() => handleToggleSidebar()}
          >
            {activeGroup && <Link to={`/${activeGroup.slug}/`} />}
            Detalles
          </MenuItem>
          {activeGroup &&
            user.memberships.find(
              membership => membership.group === activeGroup.id && membership.accepted
            ) && (
              <MenuItem
                icon={<img alt="sensla pillars" src={overview} className={classes.image} />}
                onClick={() => handleToggleSidebar()}
              >
                {activeGroup && <Link to={`/${activeGroup.slug}/tournaments`} />}
                Torneos
              </MenuItem>
            )}
          {activeTournament && (
            <>
              <div className={classes.adminContent} />
              <Typography className={classes.menuTitle}>Mi Quiniela</Typography>
              <MenuItem
                icon={<img alt="sensla pillars" src={home} className={classes.image} />}
                onClick={() => handleToggleSidebar()}
              >
                {activeGroup && (
                  <Link to={`/${activeGroup.slug}/tournament/${activeTournament.code}`} />
                )}
                Home
              </MenuItem>
              <SubMenu
                className={classes.subMenu}
                icon={<img alt="sensla pillars" src={fixtures} className={classes.image} />}
                title={!collapsed ? 'Fases' : ''}
              >
                {activeGroup &&
                  activeTournament &&
                  activeTournament.phases
                    .sort((m1, m2) => m1.number - m2.number)
                    .map((phase, index) => {
                      if (phase.active) {
                        return (
                          <MenuItem key={index} onClick={() => handleToggleSidebar()}>
                            <Link
                              to={`/${activeGroup.slug}/tournament/${activeTournament.code}/phase/${phase.name}`}
                            />
                            {phase.name}
                          </MenuItem>
                        );
                      }
                    })}
              </SubMenu>

              <MenuItem
                icon={<img alt="sensla pillars" src={ranking} className={classes.image} />}
                onClick={() => handleToggleSidebar()}
              >
                {activeTournament && activeGroup && (
                  <Link to={`/${activeGroup.slug}/tournament/${activeTournament.code}/ranking`} />
                )}
                Ranking
              </MenuItem>
            </>
          )}

          {activeGroup && activeGroup.admin === user.id && (
            <>
              <div className={classes.adminContent} />
              <Typography className={classes.menuTitle}>Configuración</Typography>

              <MenuItem
                icon={<img alt="sensla pillars" src={settings} className={classes.image} />}
                onClick={() => handleToggleSidebar()}
              >
                {activeGroup && <Link to={`/${activeGroup.slug}/settings`} />}
                Perfíl del Grupo
              </MenuItem>

              <MenuItem
                icon={<img alt="sensla pillars" src={requests} className={classes.image} />}
                onClick={() => handleToggleSidebar()}
              >
                {activeGroup && <Link to={`/${activeGroup.slug}/requests`} />}
                Solicitudes de Ingreso
              </MenuItem>

              <MenuItem
                icon={<img alt="sensla pillars" src={manage} className={classes.image} />}
                onClick={() => handleToggleSidebar()}
              >
                {activeGroup && <Link to={`/${activeGroup.slug}/users`} />}
                Gestión de Usuarios
              </MenuItem>
            </>
          )}
        </Menu>
      </SidebarContent>

      {/* <SidebarFooter className={classes.footerSideBar}>
        <FormControlLabel
          control={<Switch onChange={() => setCollapsed(!collapsed)} color="secondary" />}
          label={
            !collapsed ? <Typography className={classes.formControlLabel}>Opened</Typography> : null
          }
          color="white"
          className={classes.collapsed}
        />
        <Typography className={classes.textStatistics}>laquiniela.club 2021</Typography>
      </SidebarFooter> */}
    </ProSidebar>
  );
};
