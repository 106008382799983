import api, { request } from 'api';
// import moment from 'moment';
import queryString from 'query-string';

import { get, save } from 'utils/storage';

export const predictionsActions = {
  getPredictions: state => async (tournamentCode, group) => {
    const predictions = get('predictions');
    const query = queryString.stringify({ tournament: tournamentCode, group });
    const response = await request.get(api.predictions(query));

    if (response.error) {
      return {
        misc: {
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const predictionsToBeSaved = { ...predictions, [tournamentCode]: response };
    save('predictions', predictionsToBeSaved);

    return {
      predictions: predictionsToBeSaved,
      misc: {
        ...state.misc,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  createOrUpdatePrediction: state => async (data, id) => {
    let response;
    if (!id) {
      response = await request.post(api.predictions(), data, true);
    } else {
      response = await request.put(api.prediction(id), data, true);
    }

    if (response.error) {
      return {
        misc: {
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
          loading: false,
        },
      };
    }

    const predictions = get('predictions');

    const predictionsToBeSaved = {
      ...predictions,
      [data.tournament]: [
        ...predictions[data.tournament].filter(game => game.game !== data.game),
        response,
      ],
    };

    save('predictions', predictionsToBeSaved);

    return {
      predictions: predictionsToBeSaved,
      misc: {
        ...state.misc,
        notification: {
          status: true,
          message: 'Predicción actualizada correctamente',
          type: 'success',
        },
        loading: false,
      },
    };
  },
};
