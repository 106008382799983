import { makeStyles, createStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: colors.black(0.5),
      height: 100,
      justifyContent: 'center',
      position: 'fixed',
      zIndex: 998,
    },
    badge: {
      color: colors.black(0.4),
    },
    button: {
      boxShadow: 'none',
      color: colors.lqOrange,
      marginRight: theme.spacing(2),
    },
    buttonLink: {
      color: colors.lqOrange,
      display: 'flex',
      flex: 1,
      height: '100%',
      lineHeight: 1.2,
      textDecoration: 'none',
    },
    grow: {
      height: 100,
      position: 'absolute',
      zIndex: 999,
    },
    inputRoot: {
      color: 'inherit',
    },
    link: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      paddingLeft: 30,
      textDecoration: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuItemText: {
      color: colors.white,
      cursor: 'pointer',
      fontSize: '13px',
      textTransform: 'uppercase',
      marginRight: '20px',
      [theme.breakpoints.up('md')]: {
        fontSize: '11px',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
      },
    },
    sectionMobile: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    title: {
      cursor: 'pointer',
      height: '80%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        height: 'auto',
        maxWidth: '70%',
      },
    },
  })
);
