import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import ProfilePicture from 'components/ProfilePicture';
import { ctx } from 'store';

import { useStyles } from './styles';

const GroupProfile = () => {
  const classes = useStyles();
  const { errors, handleSubmit, register, watch, formState } = useForm();

  const actions = useContext(ctx.actions);
  const [isClosed, setClosed] = useState();
  const [hasPrizes, setPrizes] = useState();
  const {
    groups: { activeGroup },
  } = useContext(ctx.store);

  useEffect(() => {
    if (activeGroup) {
      setClosed(activeGroup.isClosed);
      setPrizes(activeGroup.hasPrizes);
    }
  }, [activeGroup]);

  const onChangeProfilePicture = async picture => {
    await actions.misc.setLoading(true);
    await actions.groups.updateGroupPicture(picture);
  };

  const onSubmit = async data => {
    if (
      (data.name && activeGroup.name !== data.name) ||
      (data.description && activeGroup.description !== data.description) ||
      activeGroup.isClosed !== isClosed ||
      activeGroup.hasPrizes !== hasPrizes ||
      activeGroup.prizeOne !== data.prizeOne ||
      activeGroup.prizeTwo !== data.prizeTwo ||
      activeGroup.prizeThree !== data.prizeThree
    ) {
      await actions.misc.setLoading(true);
      await actions.groups.updateGroup({ ...data, isClosed, hasPrizes });
    }
  };

  const handleGroupState = event => {
    setClosed(event.target.checked);
  };

  const handleGroupPrizes = event => {
    setPrizes(event.target.checked);
  };

  return (
    <Grid item xs={12}>
      {activeGroup && (
        <div className={classes.paper}>
          <Grid className={classes.socialGrid}>
            <ProfilePicture
              onUpload={onChangeProfilePicture}
              profilePicture={activeGroup.avatar ? activeGroup.avatar.url : null}
            />
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.groupStateDiv}>
              {isClosed || isClosed === false ? (
                <FormControlLabel
                  className={classes.groupStateCheck}
                  control={
                    <Checkbox
                      checked={isClosed}
                      onChange={handleGroupState}
                      icon={<LockOpenIcon style={{ color: 'white' }} />}
                      checkedIcon={<LockIcon />}
                      name="checkedH"
                    />
                  }
                  label={
                    isClosed
                      ? 'BLOQUEADO: No puede recibir solicitudes de ingreso'
                      : 'DESBLOQUEADO: Puede recibir solicitudes de ingreso'
                  }
                  classes={{
                    label: classes.groupStateLabel,
                  }}
                />
              ) : null}
            </div>
            <Typography className={classes.inputLabels}>Nombre</Typography>
            <TextField
              {...register('name')}
              variant="outlined"
              defaultValue={activeGroup.name}
              placeholder="Nombre"
              name="name"
              className={classes.inputs}
              InputProps={{
                classes: {
                  root: classes.inputProps,
                  disabled: classes.inputPropsDisabled,
                },
              }}
              inputProps={{ maxLength: 60 }}
            />
            <Typography className={classes.inputLabels}>Descripción</Typography>
            <TextField
              {...register('description')}
              variant="outlined"
              defaultValue={activeGroup.description}
              placeholder="Descripción"
              multiline
              name="description"
              className={classes.inputs}
              InputProps={{
                classes: {
                  root: classes.inputProps,
                  disabled: classes.inputPropsDisabled,
                },
              }}
              inputProps={{ maxLength: 280 }}
            />
            {hasPrizes || hasPrizes === false ? (
              <FormControlLabel
                className={classes.groupStateCheck}
                control={
                  <Checkbox
                    checked={hasPrizes}
                    onChange={handleGroupPrizes}
                    icon={<StarBorderIcon style={{ color: 'white' }} />}
                    checkedIcon={<StarIcon />}
                    name="checkedH"
                  />
                }
                label={hasPrizes ? 'Este Grupo tiene premios a repartir' : 'Grupo sin premios'}
                classes={{
                  label: classes.groupStateLabel,
                }}
              />
            ) : null}
            {hasPrizes && (
              <>
                <Typography className={classes.inputLabels}>Premio 1</Typography>
                <TextField
                  {...register('prizeOne')}
                  variant="outlined"
                  defaultValue={activeGroup.prizeOne}
                  placeholder="Premio 1"
                  name="prizeOne"
                  className={classes.inputs}
                  InputProps={{
                    classes: {
                      root: classes.inputProps,
                      disabled: classes.inputPropsDisabled,
                    },
                  }}
                  inputProps={{ maxLength: 80 }}
                />
                <Typography className={classes.inputLabels}>Premio 2</Typography>
                <TextField
                  {...register('prizeTwo')}
                  variant="outlined"
                  defaultValue={activeGroup.prizeTwo}
                  placeholder="Premio 2"
                  name="prizeTwo"
                  className={classes.inputs}
                  color="secondary"
                  InputProps={{
                    classes: {
                      root: classes.inputProps,
                      disabled: classes.inputPropsDisabled,
                    },
                  }}
                  inputProps={{ maxLength: 80 }}
                />
                <Typography className={classes.inputLabels}>Premio 3</Typography>
                <TextField
                  {...register('prizeThree')}
                  variant="outlined"
                  defaultValue={activeGroup.prizeThree}
                  placeholder="Premio 3"
                  name="prizeThree"
                  className={classes.inputs}
                  InputProps={{
                    classes: {
                      root: classes.inputProps,
                      disabled: classes.inputPropsDisabled,
                    },
                  }}
                  inputProps={{ maxLength: 80 }}
                />
              </>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={
                !formState.isDirty ||
                (!watch('name') &&
                  !watch('description') &&
                  !watch('prizeOne') &&
                  !watch('prizeTwo') &&
                  !watch('prizeThree')) ||
                (watch('name') === activeGroup.name &&
                  watch('description') === activeGroup.description &&
                  activeGroup.isClosed === isClosed &&
                  activeGroup.hasPrizes === hasPrizes &&
                  activeGroup.prizeOne === watch('prizeOne') &&
                  activeGroup.prizeTwo === watch('prizeTwo') &&
                  activeGroup.prizeThree === watch('prizeThree'))
              }
            >
              Guardar
            </Button>
          </form>
        </div>
      )}
    </Grid>
  );
};

export { GroupProfile };
