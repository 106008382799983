import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export default makeStyles(theme =>
  createStyles({
    groupDetails: {
      alignItems: 'center',
      color: colors.lqOrange,
      display: 'flex',
      fontFamily: 'Teko',
      fontSize: '1.5rem',
      justifyContent: 'center',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
    groupsGrid: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      overflowX: 'scroll',
      padding: '1vh 0',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        padding: '1vh 3vw',
      },
    },
    groupInstructor: {
      alignItems: 'center',
      color: colors.white(1),
      display: 'flex',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      justifyContent: 'center',
      margin: '0.2vh 0 1vh',
      textAlign: 'center',
    },
    groupTitle: {
      color: colors.white(1),
      fontSize: '1.5rem',
      margin: '1vh 0 0',
      textAlign: 'center',
      width: 200,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
        width: 180,
      },
    },
    cover: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: 100,
      height: 200,
      width: 200,
      [theme.breakpoints.down('md')]: {
        borderRadius: 90,
        height: 180,
        width: 180,
      },
    },
    coverGrid: {
      background: `linear-gradient(to left, ${colors.black(0.6)}, ${colors.black(0.6)})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
      height: 200,
      width: 200,
      [theme.breakpoints.down('md')]: {
        borderRadius: 90,
        height: 180,
        width: 180,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    itemGroup: {
      alignItems: 'center',
      display: 'flex',
      margin: theme.spacing(0, 2),
      minWidth: 200,
      [theme.breakpoints.down('md')]: {
        minWidth: 180,
        padding: '0 2vw',
      },
    },
    length: {
      color: colors.white(1),
      display: 'block',
      fontSize: '1rem',
      lineHeight: 1,
      margin: theme.spacing(0, 0, 0, 1),
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
        margin: theme.spacing(0, 0, 0, 1),
        textAlign: 'center',
      },
    },
    linkButton: {
      textDecoration: 'none',
    },
    noGroupsGrid: {
      alignItems: 'center',
      display: 'flex',
      height: 200,
      justifyContent: 'center',
    },
    noGroupsText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1, 0, 2),
      minHeight: '400px',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '4vh',
        minHeight: '400px',
        width: '100%',
      },
    },
    title: {
      alignItems: 'center',
      color: colors.white(1),
      display: 'flex',
      fontFamily: 'Teko',
      fontSize: '3rem',
      lineHeight: 1,
      margin: theme.spacing(0, 0, 0, 3),
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        fontSize: '2.4rem',
        margin: theme.spacing(0, 0, 1, 3),
        textAlign: 'center',
      },
    },
  })
);
