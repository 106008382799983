import api, { request } from 'api';
import queryString from 'query-string';

import { get, save } from 'utils/storage';

export const membershipsActions = {
  createMembership: state => async group => {
    const query = queryString.stringify({ group });
    const response = await request.post(api.memberships(query));

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        memberships: [...state.auth.user.memberships, response],
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };
    save('auth', auth);

    return {
      auth,
      misc: {
        ...state.misc,
        loading: false,
        notification: {
          status: true,
          message: 'Tu solicitud ha sido enviada con éxito',
          type: 'success',
        },
      },
    };
  },
  getMemberships: state => async queryData => {
    let memberships = get('ms');

    if (
      memberships &&
      memberships[queryData.group ? queryData.group : 'LQ'] &&
      (!memberships[queryData.group ? queryData.group : 'LQ'].nextPage ||
        memberships[queryData.group ? queryData.group : 'LQ'].nextPage > queryData.page)
    ) {
      return {
        memberships: {
          ...state.memberships,
          memberships: {
            ...state.memberships.memberships,
            [queryData.group ? queryData.group : 'LQ']: {
              memberships: memberships[queryData.group ? queryData.group : 'LQ'].memberships,
              nextPage: memberships[queryData.group ? queryData.group : 'LQ'].nextPage,
            },
          },
        },
        misc: {
          ...state.misc,
          fetching: false,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }

    const query = queryString.stringify(queryData);
    const response = await request.get(api.memberships(query));

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          fetching: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    let nextPage;
    if (response.length === 100) {
      nextPage = !queryData.page || queryData.page === 1 ? 2 : queryData.page + 1;
    } else {
      nextPage = false;
    }

    let responseToBeSent;
    if (
      state.memberships.memberships &&
      state.memberships.memberships[!queryData.group ? 'LQ' : queryData.group]
    ) {
      const memberships = state.memberships.memberships[!queryData.group ? 'LQ' : queryData.group]
        ? [
            ...state.memberships.memberships[!queryData.group ? 'LQ' : queryData.group].memberships,
            ...response,
          ]
        : response;

      responseToBeSent = {
        [!queryData.group ? 'LQ' : queryData.group]: {
          ...state.memberships.memberships[!queryData.group ? 'LQ' : queryData.group],
          memberships,
          nextPage,
        },
      };
    } else {
      responseToBeSent = {
        [!queryData.group ? 'LQ' : queryData.group]: {
          memberships: response,
          nextPage,
        },
      };
    }

    save('ms', responseToBeSent);

    return {
      memberships: {
        ...state.memberships,
        memberships: responseToBeSent,
      },
      misc: {
        ...state.misc,
        fetching: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  getRequests: state => async queryData => {
    const query = queryString.stringify(queryData);
    const response = await request.get(api.memberships(query));

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          fetching: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    let nextPage;
    if (response.length === 100) {
      nextPage = !queryData.page || queryData.page === 1 ? 2 : queryData.page + 1;
    } else {
      nextPage = false;
    }

    let responseToBeSent;
    if (
      state.memberships.requests &&
      state.memberships.requests[!queryData.group ? 'LQ' : queryData.group]
    ) {
      const memberships = state.memberships.requests[!queryData.group ? 'LQ' : queryData.group]
        ? [
            ...state.memberships.requests[!queryData.group ? 'LQ' : queryData.group].memberships,
            ...response,
          ]
        : response;

      responseToBeSent = {
        [!queryData.group ? 'LQ' : queryData.group]: {
          ...state.memberships.requests[!queryData.group ? 'LQ' : queryData.group],
          memberships,
          nextPage,
        },
      };
    } else {
      responseToBeSent = {
        [!queryData.group ? 'LQ' : queryData.group]: {
          memberships: response,
          nextPage,
        },
      };
    }

    return {
      memberships: {
        ...state.memberships,
        requests: responseToBeSent,
      },
      misc: {
        ...state.misc,
        fetching: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  acceptMembership: state => async (id, group) => {
    const response = await request.put(api.membership(id));

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const memberships = await get('ms');

    memberships[group].memberships.push(response);
    save('ms', memberships);

    return {
      memberships,
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
  deleteMembership: state => async (id, group) => {
    let response;
    if (id) {
      response = await request.delete(api.membership(id));
    } else {
      response = await request.delete(api.membership(`user/${group}`));
    }

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    if (id) {
      const memberships = await get('ms');

      memberships[group].memberships = memberships[group].memberships.filter(
        membership => membership.id !== id
      );

      save('ms', memberships);

      return {
        memberships,
        misc: {
          ...state.misc,
          loading: false,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }

    const userMemberships = state.auth.user.memberships.filter(
      membership => membership.groupName !== group
    );

    let userGroups = state.groups.userGroups.filter(
      evaluatedGroup => evaluatedGroup.name !== group
    );

    const updatedProfile = {
      ...state.auth,
      user: {
        ...state.auth.user,
        memberships: userMemberships,
      },
    };

    const auth = { token: state.auth.token, isAuthenticated: true, user: updatedProfile.user };
    save('auth', auth);

    return {
      auth,
      groups: {
        ...state.groups,
        userGroups,
      },
      tournaments: {
        activeTournament: undefined,
      },
      misc: {
        ...state.misc,
        loading: false,
        notification: {
          status: true,
          message: 'Has salido del grupo de manera exitosa',
          type: 'success',
        },
      },
    };
  },
  searchMemberships: state => async (username, groupName, accepted) => {
    const query = queryString.stringify({ username, groupName, accepted });

    const response = await request.get(api.membershipSearch(query));

    if (response.error) {
      return {
        ...state.misc,
        misc: {
          loading: false,
          notification: {
            status: true,
            message: response.error.body.message,
            type: 'error',
          },
        },
      };
    }

    const memberships = await get('ms');

    if (memberships && response.length) {
      for (const searchedMembership of response) {
        const exist = memberships[group].memberships.find(
          membership => membership.id === searchedMembership.id
        );
        if (!exist) {
          memberships[group].memberships.push(searchedMembership);
        }
      }

      save('ms', memberships);

      return {
        memberships,
        misc: {
          ...state.misc,
          loading: false,
          notification: { status: false, message: '', type: 'success' },
        },
      };
    }
    return {
      misc: {
        ...state.misc,
        loading: false,
        notification: { status: false, message: '', type: 'success' },
      },
    };
  },
};
