/**
 * Match valid email addresses
 *
 * Example:
 * support@ecotext.co
 */
export const validEmailRegex = /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/

/**
 * Matches only alphanumeric values
 *
 * Example:
 * 'abc', '123', 'a12c'
 */
// eslint-disable-next-line
export const onlyAlphanumeric = /[^a-z0-9]/g
