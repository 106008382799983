import { Button, Grid, Hidden, Paper, Typography } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import React, { useContext } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';

import lqImage from 'assets/images/wired/lq.png';
import { ctx } from 'store';

import useStyles from './styles';

const FeaturedGroup = ({ groups, user }) => {
  const classes = useStyles();

  const getSubtitle = subTitle => {
    return subTitle.length > 50 ? `${subTitle.substring(0, 200 - 3)}...` : subTitle;
  };

  return (
    <Grid container className={classes.root}>
      <Carousel
        autoPlay={true}
        navButtonsAlwaysVisible={true}
        animation="slide"
        indicators={true}
        className={classes.carousel}
        interval={5000}
      >
        {groups &&
          groups.map((group, index) => (
            <Grid item className={classes.oneFullWidth} key={index}>
              <Grid className={classes.oneFullWidthRight}>
                <Link to={{ pathname: `/${group.slug}` }}>
                  <Paper
                    className={classes.oneFullWidthImage}
                    style={{
                      backgroundImage: `url(${group.avatar ? group.avatar.url : lqImage})`,
                    }}
                  />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} className={classes.oneFullWidthLeft}>
                {/* <Typography className={classes.detailsTextOneFullWidth}>
                  Grupo Destacado en LQ
                </Typography> */}
                <Typography className={classes.titleOneFullWidth}>{group.name}</Typography>
                <Hidden mdDown>
                  <Typography className={classes.titleTextOneFullWidth}>
                    {getSubtitle(group.description)}
                  </Typography>
                </Hidden>
                <Typography className={classes.detailsTextOneFullWidth}>
                  <GroupIcon className={classes.icon} /> {group.subscriptions} Jugadores Inscritos
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  aria-label="know-more"
                  className={classes.knowMoreButton}
                  type="button"
                >
                  <Link className={classes.linkButton} to={{ pathname: `/${group.slug}` }}>
                    {user.memberships.find(membership => membership.group === group.id)
                      ? 'Ver Detalles'
                      : 'Unirme'}
                  </Link>
                </Button>
              </Grid>
            </Grid>
          ))}
      </Carousel>
    </Grid>
  );
};

export { FeaturedGroup };
