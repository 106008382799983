import { Button, Grid, TextField, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ctx } from 'store';

import { useStyles } from './styles';

const StepTwo = props => {
  const classes = useStyles();
  const [hasPrizes, setHasPrizes] = useState(false);
  const { errors, handleSubmit, register, watch } = useForm();
  const actions = useContext(ctx.actions);

  const onSubmit = async data => {
    if (
      (!hasPrizes && data.description && data.description.length) ||
      (hasPrizes &&
        watch('prizeOne') &&
        watch('prizeTwo') &&
        watch('prizeThree') &&
        watch('prizeOne').length &&
        watch('prizeTwo').length &&
        watch('prizeThree').length &&
        data.description &&
        data.description.length)
    ) {
      await actions.misc.setLoading(true);
      const response = await actions.groups.updateGroup({ ...data, hasPrizes });
      if (response.misc.notification.type === 'success') {
        props.nextStep();
      }
    }
  };

  const handleGroupPrizes = () => {
    setHasPrizes(!hasPrizes);
  };

  return (
    <Grid item xs={12} className={classes.root}>
      <div className={classes.paper}>
        <Typography className={classes.title}>Agreguemos una descripción y premios</Typography>
        <Typography className={classes.subtitle}>
          Una descripción que ayude a reconocer al grupo y si tiene premios presiona la estrella
          para llenarlos
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Typography className={classes.inputLabels}>Nombre del Grupo</Typography>
          <TextField
            {...register('description', { required: true })}
            variant="outlined"
            placeholder="Este es el grupo de la oficina de mi empresa."
            name="description"
            className={classes.inputs}
            multiline
            maxRows={6}
            InputProps={{
              classes: {
                root: classes.inputProps,
                disabled: classes.inputPropsDisabled,
              },
            }}
            inputProps={{ maxLength: 280 }}
          />
          <FormControlLabel
            className={classes.groupStateCheck}
            control={
              <Checkbox
                checked={hasPrizes}
                onChange={handleGroupPrizes}
                icon={<StarBorderIcon style={{ color: 'white' }} />}
                checkedIcon={<StarIcon />}
                name="checkedH"
              />
            }
            label={hasPrizes ? 'Este Grupo tiene premios a repartir' : 'Grupo sin premios'}
            classes={{
              label: classes.groupStateLabel,
            }}
          />
          {hasPrizes && (
            <>
              <Typography className={classes.inputLabels}>Premio 1</Typography>
              <TextField
                {...register('prizeOne', { required: true })}
                variant="outlined"
                placeholder="Premio 1"
                name="prizeOne"
                className={classes.inputs}
                InputProps={{
                  classes: {
                    root: classes.inputProps,
                    disabled: classes.inputPropsDisabled,
                  },
                }}
                inputProps={{ maxLength: 80 }}
              />
              <Typography className={classes.inputLabels}>Premio 2</Typography>
              <TextField
                {...register('prizeTwo', { required: true })}
                variant="outlined"
                placeholder="Premio 2"
                name="prizeTwo"
                className={classes.inputs}
                color="secondary"
                InputProps={{
                  classes: {
                    root: classes.inputProps,
                    disabled: classes.inputPropsDisabled,
                  },
                }}
                inputProps={{ maxLength: 80 }}
              />
              <Typography className={classes.inputLabels}>Premio 3</Typography>
              <TextField
                {...register('prizeThree', { required: true })}
                variant="outlined"
                placeholder="Premio 3"
                name="prizeThree"
                className={classes.inputs}
                InputProps={{
                  classes: {
                    root: classes.inputProps,
                    disabled: classes.inputPropsDisabled,
                  },
                }}
                inputProps={{ maxLength: 80 }}
              />
            </>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={
              !watch('description') ||
              !watch('description').length ||
              (hasPrizes &&
                (!watch('prizeOne') ||
                  !watch('prizeTwo') ||
                  !watch('prizeThree') ||
                  !watch('prizeOne').length ||
                  !watch('prizeTwo').length ||
                  !watch('prizeThree').length))
            }
          >
            Guardar
          </Button>
        </form>
      </div>
    </Grid>
  );
};

export { StepTwo };
