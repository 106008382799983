import React, { useEffect, useState } from 'react';
import { Button, Hidden, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

import { useStyles } from './styles';

const ConfirmButton = ({ icon, label, mainMessage, onSubmit, id }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit(id);
    setOpen(false);
  };

  return (
    <>
      {!isOpen && (
        <Hidden mdDown>
          <Button
            variant="contained"
            color="secondary"
            startIcon={icon}
            label={label}
            className={classes.button}
            onClick={handleOpen}
          >
            {mainMessage}
          </Button>
        </Hidden>
      )}
      {!isOpen && (
        <Hidden mdUp>
          <IconButton className={classes.deleteButton} color="primary" onClick={handleOpen}>
            {icon}
          </IconButton>
        </Hidden>
      )}
      {isOpen && (
        <div className={classes.confirmationDiv}>
          <IconButton className={classes.iconButton} color="primary" onClick={handleCancel}>
            <ClearIcon fontSize="small" />
          </IconButton>
          <IconButton className={classes.iconButton} color="primary" onClick={handleSubmit}>
            <DoneIcon fontSize="small" />
          </IconButton>
        </div>
      )}
    </>
  );
};

export { ConfirmButton };
