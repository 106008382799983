import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Logo from 'assets/images/wired/lqDark.png';
import { ctx } from 'store';
import { validEmailRegex } from 'utils/regex';

import { useStyles } from './styles';

const Forgot = () => {
  const history = useHistory();
  const { errors, getValues, handleSubmit, register, reset } = useForm();
  const classes = useStyles();
  const actions = useContext(ctx.actions);

  const onSubmit = async credentials => {
    await actions.misc.setLoading(true);
    const { misc } = await actions.auth.forgot(credentials.email);
    if (misc.notification.type === 'success') {
      history.push('/login');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Recupera tu contraseña de
        </Typography>
        <img alt="La Quiniela" width="240" src={Logo} style={{ marginTop: 16 }} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: validEmailRegex,
                    message: 'Correo electrónico inválido',
                  },
                })}
                placeholder="Correo electrónico"
                name="email"
                autoFocus
                className={classes.inputs}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enviar
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Volver al inicio de sesión
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export { Forgot };
