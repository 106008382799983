import { createStyles, makeStyles } from '@material-ui/core/styles';

import background from 'assets/images/background.jpg';
import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      fontWeight: 'bold',
      margin: theme.spacing(1, 0, 0),
      minHeight: 50,
      textTransform: 'none',
    },
    form: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      width: '30%', // Fix IE 11 issue.
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(0),
        width: '90%', // Fix IE 11 issue.
      },
    },
    inputProps: {
      border: `1px solid ${colors.white(1)}`,
      color: colors.white(1),
      fontWeight: 'bold',
    },
    inputPropsDisabled: {
      color: colors.white(1),
    },
    inputs: {
      backgroundColor: colors.white(0.2),
      color: colors.white(1),
      fontFamily: 'Poppins',
      margin: theme.spacing(1, 0),
      width: '100%', // Fix IE 11 issue.
    },
    paper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      margin: theme.spacing(0, 6),
      overflowY: 'scroll',
      width: '100vw',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        margin: theme.spacing(0, 1),
        marginTop: 120,
        paddingBottom: 150,
      },
    },
    root: {
      background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${background})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      position: 'fixed',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0),
      },
    },
  })
);
