import React from 'react';
import { Button, Dialog, DialogActions, Grid, Slide, Typography } from '@material-ui/core';

import { colors } from 'styles';

import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({
  status = false,
  handleStatus,
  message,
  buttonLeftText,
  buttonRightText,
  handleRightButton,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={status} onClose={() => handleStatus(false)} TransitionComponent={Transition}>
      <Grid className={classes.modalContent}>
        <Grid>{message}</Grid>
        <DialogActions className={classes.modalActions}>
          {!!buttonRightText && (
            <Button
              variant="contained"
              onClick={handleRightButton}
              color="secondary"
              name={buttonRightText}
              className={classes.button}
            >
              <Typography className={classes.buttonText}>{buttonRightText}</Typography>
            </Button>
          )}
          <Button
            variant="text"
            onClick={() => handleStatus(false)}
            color="secondary"
            className={classes.button}
            style={{ border: '0px solid transparent', boxShadow: 'none' }}
          >
            <Typography style={{ color: colors.white(1) }} className={classes.buttonText}>
              {buttonLeftText}
            </Typography>
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export { Modal };
