import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Flag from 'react-world-flags';

import ConfirmButton from 'components/ConfirmButton';
import { ctx } from 'store';
import { colors } from 'styles';

import { useStyles } from './styles';

const Users = () => {
  const classes = useStyles();
  const [membershipsToShow, setMembershipsToShow] = useState([]);
  const [nextPage, setNextPage] = useState();
  const [search, setSearch] = useState(undefined);

  const {
    auth: { user },
    groups: { activeGroup },
    misc: { fetching },
    memberships: { memberships },
  } = useContext(ctx.store);
  const actions = useContext(ctx.actions);

  useEffect(() => {
    if (
      memberships &&
      memberships[!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name] &&
      activeGroup
    ) {
      setMembershipsToShow(
        memberships[!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name].memberships
      );
      setNextPage(
        memberships[!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name].nextPage
      );
    }
  }, [memberships, location]);

  const fetchMembershipsPage = async () => {
    await actions.misc.setFetching(true);
    await actions.memberships.getMemberships({
      group: activeGroup.name,
      active: true,
      page: nextPage ? nextPage : 1,
    });
  };

  const handleDelete = async id => {
    await actions.misc.setLoading(true);
    const response = await actions.memberships.deleteMembership(id, activeGroup.name);
    if (!response.misc.notification.status) {
      setSearch(undefined);
    }
  };

  const handleSearch = async () => {
    await actions.misc.setLoading(true);
    await actions.memberships.searchMemberships(search, activeGroup.name, true);
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: fetching,
    hasNextPage:
      !memberships ||
      !memberships[!activeGroup || !activeGroup.name ? 'LQ' : activeGroup.name] ||
      nextPage
        ? true
        : false,
    onLoadMore: fetchMembershipsPage,
    disabled: activeGroup ? false : true,
    rootMargin: '0px 0px 20px 0px',
    delayInMs: 500,
  });

  return (
    <Grid item xs={12}>
      {activeGroup && (
        <>
          <Typography className={classes.rankingTitle}>
            Usuarios de {activeGroup && activeGroup.name ? activeGroup.name : 'LQ'}
          </Typography>
          <List className={classes.list}>
            <ListSubheader className={classes.listHeader}>
              <div className={classes.headerTitles}>
                <ListItemText className={classes.columnPosition}></ListItemText>
                <ListItemText
                  className={classes.columnUser}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <Typography className={classes.headerText}>Usuario</Typography>
                </ListItemText>
                <ListItemText
                  className={classes.columnFlag}
                  style={{ justifyContent: 'flex-start' }}
                >
                  <Typography className={classes.headerText}>País</Typography>
                </ListItemText>
                <ListItemText className={classes.columnPoints}></ListItemText>
              </div>
              {membershipsToShow && membershipsToShow.length ? (
                <div className={classes.headerSearch}>
                  <TextField
                    label="Busca un usuario"
                    InputLabelProps={{
                      style: {
                        color: colors.lqOrange,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputProps,
                      },
                    }}
                    variant="outlined"
                    color="secondary"
                    className={classes.inputs}
                    onChange={event => setSearch(event.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={handleSearch}
                  >
                    Buscar
                  </Button>
                </div>
              ) : null}
            </ListSubheader>
            {membershipsToShow && membershipsToShow.length
              ? (search && search.length
                  ? membershipsToShow.filter(m => m.username.includes(search))
                  : membershipsToShow
                ).map((userItem, index) => (
                  <ListItem key={index} className={classes.listItem}>
                    <div className={classes.columnPosition}>
                      <Avatar
                        src={
                          user.username === userItem.username
                            ? user.profiles[0].avatar.url
                            : userItem.avatar
                        }
                        className={classes.avatar}
                      />
                    </div>
                    <div className={classes.columnUser}>
                      <Typography className={classes.itemText} noWrap>
                        {userItem.username}
                      </Typography>
                    </div>
                    <div className={classes.columnFlag}>
                      <Flag
                        code={
                          user.username === userItem.username
                            ? user.profiles[0].country
                            : userItem.country
                        }
                        className={classes.flag}
                      />
                    </div>
                    <ListItemText className={classes.columnPoints}>
                      {user.username === userItem.username &&
                      activeGroup.admin === user.id ? null : (
                        <ConfirmButton
                          mainMessage="Eliminar"
                          icon={<DeleteIcon fontSize="small" />}
                          onSubmit={handleDelete}
                          id={userItem.id}
                        />
                      )}
                    </ListItemText>
                  </ListItem>
                ))
              : !fetching && (
                  <Grid xs={12} item className={classes.noUsersGrid}>
                    <Typography className={classes.noUsersText}>
                      No hay usuarios en este grupo!
                    </Typography>
                  </Grid>
                )}
            <div ref={infiniteRef} />
            {fetching && <ListItem className={classes.loadingGrid}>Loading</ListItem>}
          </List>
        </>
      )}
    </Grid>
  );
};

export { Users };
