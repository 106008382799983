import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import lq from 'assets/images/wired/lq.png';
import bg from 'assets/images/wired/groupBg.jpg';
import { ctx } from 'store';

import { useStyles } from './styles';

const GroupHome = () => {
  const classes = useStyles();
  const history = useHistory();

  const {
    auth: { user },
    groups: { activeGroup, userGroups },
  } = useContext(ctx.store);
  const actions = useContext(ctx.actions);

  const handleClick = async () => {
    if (
      userGroups.find(group => group.id === activeGroup.id) ||
      user.memberships.find(
        membership => membership.group === activeGroup.id && membership.accepted
      )
    ) {
      await actions.misc.setLoading(true);
      await actions.memberships.deleteMembership(undefined, activeGroup.name);
    } else {
      await actions.misc.setLoading(true);
      await actions.memberships.createMembership(activeGroup.name);
    }
  };

  return (
    <Grid className={classes.root}>
      {activeGroup && (
        <Grid item xs={12} className={classes.grid}>
          <Paper
            style={{ backgroundImage: `url(${activeGroup.cover ? activeGroup.cover.url : bg})` }}
            className={classes.cover}
          />
          <Grid item xs={12} className={classes.actionsGrid}>
            <Paper className={classes.avatarGrid}>
              <div
                style={{
                  backgroundImage: `url(${activeGroup.avatar ? activeGroup.avatar.url : lq})`,
                  backgroundSize: activeGroup.avatar ? '100%' : '70%',
                }}
                className={classes.avatar}
              />
            </Paper>
            <Typography className={classes.groupTitle}>{activeGroup.name}</Typography>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={handleClick}
              disabled={
                user.memberships.find(
                  membership =>
                    membership.group === activeGroup.id &&
                    !membership.accepted &&
                    !userGroups.find(group => group.id === activeGroup.id)
                ) ||
                (activeGroup && activeGroup.isClosed)
              }
            >
              {userGroups.find(group => group.id === activeGroup.id) ||
              user.memberships.find(
                membership => membership.group === activeGroup.id && membership.accepted
              )
                ? 'Salir del Grupo'
                : user.memberships.find(
                    membership => membership.group === activeGroup.id && !membership.accepted
                  )
                ? 'Acceso Solicitado'
                : activeGroup && activeGroup.isClosed
                ? 'Temporalmente Cerrado'
                : 'Solicitar Acceso'}
            </Button>
          </Grid>
          <Typography className={classes.itemText}>{activeGroup.description}</Typography>
          {activeGroup.hasPrizes && (
            <Grid item xs={12} className>
              <Typography className={classes.prizesTitle}>
                No te pierdas de estos fabulosos premios
              </Typography>
              <Grid container direction="row">
                {activeGroup.prizeTwo && (
                  <Grid item xs={12} md={4} className={classes.prizeGrid}>
                    <Typography className={classes.prizesPlace}>1er Premio</Typography>
                    {activeGroup.prizeOneImage && (
                      <Paper className={classes.prizeImageGrid}>
                        <div
                          style={{
                            backgroundImage: `url(${
                              activeGroup.prizeOneImage ? activeGroup.prizeOneImage.url : null
                            })`,
                          }}
                          className={classes.prizeImage}
                        />
                      </Paper>
                    )}
                    <Typography className={classes.itemText}>{activeGroup.prizeOne}</Typography>
                  </Grid>
                )}
                {activeGroup.prizeTwo && (
                  <Grid item xs={12} md={4} className={classes.prizeGrid}>
                    <Typography className={classes.prizesPlace}>2do Premio</Typography>
                    {activeGroup.prizeTwoImage && (
                      <Paper className={classes.prizeImageGrid}>
                        <div
                          className={classes.prizeImage}
                          style={{
                            backgroundImage: `url(${
                              activeGroup.prizeTwoImage ? activeGroup.prizeTwoImage.url : null
                            })`,
                          }}
                        />
                      </Paper>
                    )}
                    <Typography className={classes.itemText}>{activeGroup.prizeTwo}</Typography>
                  </Grid>
                )}
                {activeGroup.prizeThree && (
                  <Grid item xs={12} md={4} className={classes.prizeGrid}>
                    <Typography className={classes.prizesPlace}>3er Premio</Typography>
                    {activeGroup.prizeThreeImage && (
                      <Paper className={classes.prizeImageGrid}>
                        <div
                          className={classes.prizeImage}
                          style={{
                            backgroundImage: `url(${
                              activeGroup.prizeThreeImage ? activeGroup.prizeThreeImage.url : null
                            })`,
                          }}
                        />
                      </Paper>
                    )}
                    <Typography className={classes.itemText}>{activeGroup.prizeThree}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export { GroupHome };
