import { Button, Grid, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Header from 'components/Header';
import GroupList from 'components/GroupList';
import { ctx } from 'store';
// import { validEmailRegex } from 'utils/regex';

import { useStyles } from './styles';

const Search = () => {
  const classes = useStyles();
  const history = useHistory();
  const actions = useContext(ctx.actions);
  const { handleSubmit, register } = useForm();
  const { groups } = useContext(ctx.store);

  const goToHome = () => {
    history.push('/home');
  };

  const handleSearch = async data => {
    if (data.search && data.search.length) {
      await actions.misc.setLoading(true);
      await actions.groups.searchGroups(data.search);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Header />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(handleSearch)}>
          <TextField
            {...register('search', {
              required: true,
            })}
            variant="outlined"
            placeholder="Busca un grupo"
            name="search"
            className={classes.inputs}
            InputProps={{
              classes: {
                root: classes.inputProps,
                disabled: classes.inputPropsDisabled,
              },
            }}
            autoFocus
            color="secondary"
            defaultValue={groups.searchTerm}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleSearch}
          >
            Buscar
          </Button>
        </form>
        <GroupList
          groups={groups.search}
          title={`Resultados para ${groups.searchTerm}`}
          showLength={true}
        />
        <div className={classes.form}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={goToHome}
          >
            Volver al Home
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export { Search };
