import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      fontSize: '0.7rem',
      fontWeight: 'bold',
    },
    confirmationDiv: {
      display: 'flex',
      flexDirection: 'row',
    },
    deleteButton: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: colors.lqOrange,
        padding: 5,
      },
    },
    iconButton: {
      backgroundColor: colors.lqOrange,
      margin: theme.spacing(0, 1),
      padding: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 0.7),
        padding: theme.spacing(0.3),
      },
    },
  })
);
