import { Grid, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import moment from 'moment';
import React from 'react';

import EditIcon from 'assets/images/draw.png';
import StatsIcon from 'assets/images/barChart.png';
import ShareIcon from 'assets/images/share.png';

import { useStyles } from './styles';

const GameCard = ({ game, finished, prediction, handleEdit, handleStats }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const openModalToEdit = () => {
    handleEdit(game, prediction);
  };

  const openModalStats = () => {
    handleStats(game);
  };

  return (
    <Paper className={classes.paper}>
      <Grid className={classes.info}>
        <IconButton size="small" onClick={openModalStats} className={classes.statsButton}>
          <img src={StatsIcon} className={classes.statsIcon} fontSize="small" />
        </IconButton>
        <Grid className={classes.infoColumn}>
          <Typography className={classes.phase} noWrap>
            {`Partido ${game.game} - ${game.phase.name}`}
          </Typography>
          <Typography className={classes.datePlace} noWrap>
            {moment(game.datetime).format('ddd DD MMM YYYY - HH:mm')} {'- '}
            {game.stadium.city}
          </Typography>
        </Grid>
        <IconButton size="small" className={classes.statsButton}>
          <img src={ShareIcon} className={classes.statsIcon} fontSize="small" />
        </IconButton>
      </Grid>
      <Grid xs={12} className={classes.details}>
        {!matches ? (
          // Desktop View
          <Grid xs={12} className={classes.predictions}>
            <Grid xs={4} className={classes.team}>
              <img src={game.team1.flag ? game.team1.flag.url : null} className={classes.flag} />
              <Typography className={classes.teamName} noWrap>
                {game.team1.name}
              </Typography>
            </Grid>
            <Grid xs={4} className={classes.team}>
              <Grid xs={12} className={classes.predictionsFull}>
                <Grid
                  xs={4}
                  className={classes.predictionCell}
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  {game &&
                  prediction &&
                  game.hasPenalties &&
                  prediction.scoreTeam1 === prediction.scoreTeam2 &&
                  prediction.penaltiesWinner &&
                  prediction.penaltiesWinner === game.team1.name ? (
                    <SportsSoccerIcon className={classes.penaltyIcon} />
                  ) : null}
                  <Typography className={classes.predictionText} noWrap>
                    {prediction && prediction.scoreTeam1 >= 0
                      ? prediction.scoreTeam1
                      : prediction && prediction.scoreTeam2 >= 0
                      ? 0
                      : '-'}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography className={classes.vsText} noWrap>
                    Pronóstico
                  </Typography>
                </Grid>
                <Grid
                  xs={4}
                  style={{
                    justifyContent: 'center',
                  }}
                  className={classes.predictionCell}
                >
                  <Typography className={classes.predictionText} noWrap>
                    {prediction && prediction.scoreTeam2 >= 0
                      ? prediction.scoreTeam2
                      : prediction && prediction.scoreTeam1 >= 0
                      ? 0
                      : '-'}
                  </Typography>
                  {game &&
                  prediction &&
                  game.hasPenalties &&
                  prediction.scoreTeam1 === prediction.scoreTeam2 &&
                  prediction.penaltiesWinner &&
                  prediction.penaltiesWinner === game.team2.name ? (
                    <SportsSoccerIcon className={classes.penaltyIcon} />
                  ) : null}
                </Grid>
              </Grid>
              {finished ? (
                <Grid xs={12} className={classes.predictionsFull}>
                  <Grid
                    xs={4}
                    className={classes.predictionCell}
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    {game &&
                    game.hasPenalties &&
                    game.scoreTeam1 === game.scoreTeam2 &&
                    game.penaltiesWinner &&
                    game.penaltiesWinner === game.team1.name ? (
                      <SportsSoccerIcon
                        className={classes.penaltyIcon}
                        style={{ color: 'white' }}
                      />
                    ) : null}
                    <Typography className={classes.resultText} noWrap>
                      {game.result ? game.result.scoreTeam1 : '-'}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography className={classes.vsText} noWrap>
                      Resultados
                    </Typography>
                  </Grid>
                  <Grid
                    xs={4}
                    style={{
                      justifyContent: 'center',
                    }}
                    className={classes.predictionCell}
                  >
                    <Typography className={classes.resultText} noWrap>
                      {game.result ? game.result.scoreTeam2 : '-'}
                    </Typography>
                    {game &&
                    game.hasPenalties &&
                    game.scoreTeam1 === game.scoreTeam2 &&
                    game.penaltiesWinner &&
                    game.penaltiesWinner === game.team2.name ? (
                      <SportsSoccerIcon
                        className={classes.penaltyIcon}
                        style={{ color: 'white' }}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              ) : (
                <Grid className={classes.predictions} xs={12}>
                  <IconButton size="small" onClick={openModalToEdit}>
                    <img src={EditIcon} className={classes.edit} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid xs={4} className={classes.team}>
              <img src={game.team2.flag ? game.team2.flag.url : null} className={classes.flag} />
              <Typography className={classes.teamName} noWrap>
                {game.team2.name}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          // Mobile View
          <Grid xs={12}>
            <Grid className={classes.teams} xs={12}>
              <Grid className={classes.teamLeft}>
                <Grid className={classes.team}>
                  <img
                    src={game.team1.flag ? game.team1.flag.url : null}
                    className={classes.flag}
                  />
                  <Typography className={classes.teamName} noWrap>
                    {game.team1.name}
                  </Typography>
                </Grid>
                <Grid className={classes.team}>
                  <img
                    src={game.team2.flag ? game.team2.flag.url : null}
                    className={classes.flag}
                  />
                  <Typography className={classes.teamName} noWrap>
                    {game.team2.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.predictions} xs={12}>
              <Grid
                xs={3}
                className={classes.predictionCell}
                style={{
                  justifyContent: 'flex-end',
                }}
              >
                {game &&
                prediction &&
                game.hasPenalties &&
                prediction.scoreTeam1 === prediction.scoreTeam2 &&
                prediction.penaltiesWinner &&
                prediction.penaltiesWinner === game.team1.name ? (
                  <SportsSoccerIcon className={classes.penaltyIcon} />
                ) : null}
                <Typography className={classes.predictionText} noWrap>
                  {prediction && prediction.scoreTeam1 >= 0
                    ? prediction.scoreTeam1
                    : prediction && prediction.scoreTeam2 >= 0
                    ? 0
                    : '-'}
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.predictions}>
                <Typography className={classes.vsText} noWrap>
                  Pronóstico
                </Typography>
              </Grid>
              <Grid xs={3} className={classes.predictionCell}>
                <Typography className={classes.predictionText} noWrap>
                  {prediction && prediction.scoreTeam2 >= 0
                    ? prediction.scoreTeam2
                    : prediction && prediction.scoreTeam1 >= 0
                    ? 0
                    : '-'}
                </Typography>
                {game &&
                prediction &&
                game.hasPenalties &&
                prediction.scoreTeam1 === prediction.scoreTeam2 &&
                prediction.penaltiesWinner &&
                prediction.penaltiesWinner === game.team2.name ? (
                  <SportsSoccerIcon className={classes.penaltyIcon} />
                ) : null}
              </Grid>
            </Grid>
            {finished ? (
              <Grid className={classes.predictions} xs={12}>
                <Grid
                  xs={3}
                  className={classes.predictionCell}
                  style={{
                    justifyContent: 'flex-end',
                  }}
                >
                  {game &&
                  game.hasPenalties &&
                  game.result.scoreTeam1 === game.result.scoreTeam2 &&
                  game.result.penaltiesWinner &&
                  game.result.penaltiesWinner === game.team1.name ? (
                    <SportsSoccerIcon style={{ fontSize: 16, color: 'white' }} />
                  ) : null}
                  <Typography className={classes.resultText} noWrap>
                    {game.result ? game.result.scoreTeam1 : '-'}
                  </Typography>
                </Grid>
                <Grid xs={6} className={classes.predictions}>
                  <Typography className={classes.vsText} noWrap>
                    Resultados
                  </Typography>
                </Grid>
                <Grid xs={3} className={classes.predictionCell}>
                  <Typography className={classes.resultText} noWrap>
                    {game.result ? game.result.scoreTeam2 : '-'}
                  </Typography>
                  {game &&
                  game.hasPenalties &&
                  game.result.scoreTeam1 === game.result.scoreTeam2 &&
                  game.result.penaltiesWinner &&
                  game.result.penaltiesWinner === game.team2.name ? (
                    <SportsSoccerIcon style={{ fontSize: 16, color: 'white' }} />
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.predictions} xs={12}>
                <IconButton size="small" onClick={openModalToEdit}>
                  <img src={EditIcon} className={classes.edit} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {finished && prediction ? (
        <Typography className={classes.points} noWrap>
          {`${prediction.pointsEarned ? prediction.pointsEarned : 0}${
            prediction.pointsEarned === 1 ? 'pt' : 'pts'
          }`}
        </Typography>
      ) : null}
    </Paper>
  );
};

export { GameCard };
