import { Paper } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';

const TournamentCard = ({ tournament, activeGroup }) => {
  const classes = useStyles();

  return (
    <Paper
      className={
        !tournament.isFinished ? classes.activeTournamentPaper : classes.pastTournamentPaper
      }
      style={{
        backgroundImage: `url(${tournament.image ? tournament.image.url : null})`,
      }}
    >
      <Link
        className={classes.cupLink}
        to={activeGroup && tournament && `/${activeGroup.slug}/tournament/${tournament.code}`}
      >
        <div className={classes.cupInfo}>
          <img
            src={tournament.cupImage ? tournament.cupImage.url : null}
            className={!tournament.isFinished ? classes.cup : classes.pastCup}
          />
          <div
            to={activeGroup && tournament && `/${activeGroup.slug}/tournament/${tournament.code}`}
            className={classes.link}
          >
            {tournament.name}
          </div>
        </div>
      </Link>
    </Paper>
  );
};

export { TournamentCard };
