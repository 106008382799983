import { createStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from 'styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    actionsGrid: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    avatar: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 100,
      height: 200,
      width: 200,
      [theme.breakpoints.down('md')]: {
        borderRadius: 80,
        height: 160,
        width: 160,
      },
    },
    avatarGrid: {
      background: `linear-gradient(to left, ${colors.black(0.9)}, ${colors.black(0.9)})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
      height: 200,
      marginLeft: 40,
      marginTop: -80,
      width: 200,
      [theme.breakpoints.down('md')]: {
        borderRadius: 80,
        height: 160,
        marginLeft: 0,
        width: 160,
      },
    },
    button: {
      margin: theme.spacing(2, 0),
      marginRight: 40,
      maxHeight: 50,
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
    },
    cover: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '36%',
      minHeight: 280,
    },
    grid: {
      height: '100%',
      padding: theme.spacing(0),
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        // padding: theme.spacing(2),
      },
    },
    groupTitle: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2.4rem',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2rem',
        margin: theme.spacing(1),
      },
    },
    itemText: {
      color: colors.white(1),
      display: 'flex',
      fontSize: '1.2rem',
      margin: theme.spacing(2, 2, 4, 0),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '1rem',
        margin: theme.spacing(1, 1, 1, 0),
      },
    },
    prizeGrid: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    prizeImage: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 95,
      height: 190,
      width: 190,
      [theme.breakpoints.down('md')]: {
        borderRadius: 85,
        height: 170,
        width: 170,
      },
    },
    prizeImageGrid: {
      background: `linear-gradient(to left, ${colors.lqOrange}, ${colors.lqBlue})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
      height: 200,
      width: 200,
      [theme.breakpoints.down('md')]: {
        borderRadius: 90,
        height: 180,
        width: 180,
      },
    },
    prizesPlace: {
      color: colors.lqOrange,
      display: 'flex',
      fontFamily: 'Teko',
      fontSize: '2rem',
      margin: theme.spacing(2, 0, 1),
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '1.6rem',
        margin: theme.spacing(1),
      },
    },
    prizesTitle: {
      color: colors.lqOrange,
      fontFamily: 'Teko',
      fontSize: '2.4rem',
      margin: theme.spacing(6, 2, 0),
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        fontSize: '2rem',
        margin: theme.spacing(1),
      },
    },
    root: {
      height: 'auto',
      overflowY: 'scroll',
      [theme.breakpoints.down('md')]: {
        height: '90%',
      },
    },
  })
);
